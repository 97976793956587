import {
  FETCH_INFOGRAPHICS,
  FETCH_INFOGRAPHICS_FAILED,
  FETCH_INFOGRAPHICS_SUCCESS,

  ADD_INFOGRAPHICS,
  ADD_INFOGRAPHICS_FAILED,
  ADD_INFOGRAPHICS_SUCCESS,

  DELETE_INFOGRAPHICS,
  DELETE_INFOGRAPHICS_COMPLETE,
  DELETE_INFOGRAPHICS_FAILED,

} from "./types";
import { fetchDocumentService, createDocumentService, deleteDocumentService } from "../Document/api";
import { toast } from "react-toastify";

export const deleteInfographicsAction =
  ({ token, params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: DELETE_INFOGRAPHICS, ids: params?.policyIds });
      await deleteDocumentService(token, params)();
      dispatch({ type: DELETE_INFOGRAPHICS_COMPLETE, ids: params?.policyIds });

      toast.success("Delete Successful");
      return true;
    } catch (error) {
      dispatch({ type: DELETE_INFOGRAPHICS_FAILED });
      toast.error(error?.response?.data?.message || "Delete Failed");
    }
  };

export const fetchInfographicsAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_INFOGRAPHICS });
    const response = await fetchDocumentService({
      type: "infographic",
      ...params
    })();
    dispatch({
      type: FETCH_INFOGRAPHICS_SUCCESS,
      infographics: response?.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_INFOGRAPHICS_FAILED });
  }
};

export const addInfographicsAction =
  ({ token, params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_INFOGRAPHICS });
      const formData = new FormData();
      formData.append("title", params?.title);
      formData.append("file", params?.file);
      formData.append('thumbnail', params.thumbnail);
      formData.append("type", "infographic");
      const response = await createDocumentService(token)(formData);
      dispatch({ type: ADD_INFOGRAPHICS_SUCCESS, infographic: response?.data });
      toast.success("Infographic Added Successfully");
      return true;
    } catch (error) {
      dispatch({ type: ADD_INFOGRAPHICS_FAILED });
      toast.error("Error! We could not add infographics");
    }
  };