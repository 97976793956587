import React from 'react';
import { dataReducer, initialState } from './reducer';
import useThunkReducer from '../../HOC/useThunkReducer';

export const DataContext = React.createContext();

const DataContextWrapper = ({ children }) => {

    const [dataState, dispatch] = useThunkReducer(dataReducer, initialState);

    const value = {
        dataState,
        dispatch,
    }
    return (<DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>)
}

export default DataContextWrapper;

