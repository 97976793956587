import { ReactComponent as Check } from "../Assets/Icons/Check.svg";
import "./ogp.css";
import dateFormat from "dateformat";

const Activities = ({ activity }) => {
  return (
    <div className="bg-lynx rounded-2xl p-6 mt-6">
      <div>
        <div className="md:flex">
          <div>
            <span className="hidden md:contents">
              <Check />
            </span>
          </div>
          <p className="text-ebony text-base leading-8 md:pl-6 md:text-lg">
            {activity.name}
          </p>
        </div>
        <div className="lg:flex">
          <div className="md:flex md:justify-between md:py-10">
            <div className="py-6 lg:pr-14">
              <h4 className="text-grey">LEAD CSO</h4>
              <div className="w-20 h-8 mt-3">
                <img
                  className="h-full w-full object-cover"
                  src={activity?.icon}
                  alt={activity.name}
                />
              </div>
            </div>
            <div className="bg-white rounded-lg md:flex md:items-center md:justify-between lg:mr-14 flex-shrink-0">
              <div className="dates">
                <h5>Start Date</h5>
                <p>
                  {" "}
                  {!!activity?.startDate &&
                    dateFormat(activity.startDate, "mmmm, dd")}
                </p>
              </div>
              <div className="dates">
                <h5>END Date</h5>
                <p>
                  {" "}
                  {!!activity?.endDate &&
                    dateFormat(activity.endDate, "mmmm, dd")}
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center md:justify-between lg:justify-evenly">
            <div className="py-6 lg:pr-14">
              <h3 className="text-grey">TARGET YEAR</h3>
              <p className="font-semibold text-xl leading-30">
                {" "}
                {!!activity?.endDate && dateFormat(activity.endDate, "yyyy")}
              </p>
            </div>
            <div>
              <h6 className="text-grey pb-4">% COMPLETED</h6>
              <div className="flex items-center justify-between font-semibold text-xs text-chaos">
                <div id="percent" className="w-230 md:w-425 lg:w-168 h-3">
                  <div
                    className="per"
                    style={{ width: activity.completeRate + "%" }}
                  ></div>
                </div>
                <p className="ml-2">{activity?.completeRate}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Activities;
