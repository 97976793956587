import "./focus.css";
import FocusCard from "./FocusCard";
const FocusList = () => {
  const focusData = [
    {
      title: "Beneficial Ownership",
      description:
        "Beneficial Ownership is a means of disclosing the real identity of person(s) behind oil corporations or companies that benefit from oil, gas and minerals in order to prevent corruption or tax evasion",
      clearUrl:
        "https://res.cloudinary.com/budgit/image/upload/q_auto:low/v1638360904/FIXOUROIL/WhatsApp_Image_2021-11-25_at_10.06.17_AM_qsvjv0.jpg",
      darkUrl:
        "https://res.cloudinary.com/budgit/image/upload/e_grayscale,q_auto:low/v1638360904/FIXOUROIL/WhatsApp_Image_2021-11-25_at_10.06.17_AM_qsvjv0.jpg",
    },
    {
      title: "NNPC Reform",
      description:
        "NNPC Reform seeks to curb the opacity that has beclouded our National Oil Company.",
      clearUrl:
        "https://res.cloudinary.com/budgit/image/upload/q_auto:eco/v1619452903/FIXOUROIL/Blog_Banner_adxggr.png",
      darkUrl:
        "https://res.cloudinary.com/budgit/image/upload/e_grayscale,q_auto:eco/v1619452903/FIXOUROIL/Blog_Banner_adxggr.png",
    },
    {
      title: "Industry Legislation",
      description:
        "Industry Legislation is the various legislative enactments governing the oil and gas industry in Nigeria.",
      clearUrl:
        "https://res.cloudinary.com/budgit/image/upload/q_auto:eco/v1622806067/FIXOUROIL/Industry_Legislation_img_ndcl6t.jpg",
      darkUrl:
        "https://res.cloudinary.com/budgit/image/upload/e_grayscale,q_auto:eco/v1622806067/FIXOUROIL/Industry_Legislation_img_ndcl6t.jpg",
    },
    {
      title: "Revenue accountability",
      description:
        "Revenue Accountability is a means of ensuring accountability in revenue generation in the Nigeria oil and gas industry.",
      clearUrl:
        "https://res.cloudinary.com/budgit/image/upload/q_auto:eco/v1622806068/FIXOUROIL/Revenue_accountability_img_dd3xqc.png",
      darkUrl:
        "https://res.cloudinary.com/budgit/image/upload/e_grayscale,q_auto:eco/v1622806068/FIXOUROIL/Revenue_accountability_img_dd3xqc.png",
    },
  ];
  return (
    <div className="lg:flex w-full  relative ">
      {focusData.map((value) => (
        <FocusCard key={value?.title} focus={value} />
      ))}
    </div>
  );
};

export default FocusList;
