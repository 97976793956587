import React from "react";
import { blogReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const BlogContext = React.createContext();

const BlogWrapper = ({ children }) => {
  const [blogState, dispatch] = useThunkReducer(
    blogReducer,
    initialState
  );

  const value = {
    blogState,
    dispatch,
  };
  return (
    <BlogContext.Provider value={value}>
      {children}
    </BlogContext.Provider>
  );
};

export default BlogWrapper;
