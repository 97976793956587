import { Suspense, lazy } from "react";
import Fallback from "./Commons/Fallback";
import routes from "./utils/routes";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Navigation from "./Commons/Navigation";
import Footer from "./Commons/Footer";
import Ogp from "./OGPTracker";
import Page404 from "./Errors/404Page";
import Page403 from "./Errors/403Page";
import Page500 from "./Errors/500Page";
import ScrollToTop from "./Commons/ScrollToTop";

import LegalContextWrapper from "./Context/LegalPolicy";
import InfographicsWrapper from "./Context/Infographics";
import PolicyContext from "./Context/PolicyBrief";
import PublicationContext from "./Context/Publications";
import VideoContext from "./Context/Video";
import BlogContext from "./Context/Blog";
import UserContext from "./Context/User";
import AuthContext from "./Context/Auth";
import DataContext from "./Context/Data";
import CommitmentContext from "./Context/Commitment";
import PiaContext from "./Context/PIA";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const About = lazy(() => import("./About"));
// const LegalFramework = lazy(() => import("./LegalFramework"));
const PolicyBrief = lazy(() => import("./PolicyBrief"));
// const OtherPublications = lazy(() => import("./OtherPublications"));
const OilAndGas = lazy(() => import("./OilandGas"));
const Blog = lazy(() => import("./Blog"));
const SingleBlog = lazy(() => import("./Blog/SingleBlog"));
const Data = lazy(() => import("./Data"));
const Infographics = lazy(() => import("./Infographics"));
const Videos = lazy(() => import("./Videos"));
const Admin = lazy(() => import("./Admin"));
const Login = lazy(() => import("./Admin/Auth/Login"));
const InnerData = lazy(() => import("./Data/InnerData"));
const PIA = lazy(() => import("./PIA"));
const ExtractiveProjects = lazy(() => import("./ExtractiveProjects"));
const CorruptionCases = lazy(() => import("./CorruptionCases"));
const AfricanMiningVision = lazy(() => import("./AfricanMiningVision"));

function App() {
  return (
    <>
      <ToastContainer
        transition={Slide}
        // position="top-right"
        // autoClose={5000}
        // hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        // rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Route
        exact
        path={[
          routes.home,
          routes.legalFramework,
          routes.about,
          routes.policybrief,
          routes.otherPublications,
          routes.oilAndGas,
          routes.blog,
          routes.ogp,
          routes.empty,
          routes.page404,
          routes.page403,
          routes.page500,
          routes.data,
          routes.infographics,
          routes.videos,
          routes.pia,
          routes.extractiveProjects,
          routes.corruptionCases,
          routes.africanMiningVision,
          `${routes.singleBlog}/:id`,
          `${routes.innerData}/:id`,
        ]}
      >
        <Navigation />
      </Route>
      <div className="layout">
        <Suspense fallback={<Fallback />}>
          <AuthContext>
            <CommitmentContext>
              <InfographicsWrapper>
                <LegalContextWrapper>
                  <PolicyContext>
                    <PublicationContext>
                      <UserContext>
                        <VideoContext>
                          <BlogContext>
                            <DataContext>
                              <PiaContext>
                                <ScrollToTop />
                                <Switch>
                                  <Route
                                    exact
                                    path={routes.home}
                                    component={Home}
                                  />
                                  <Route
                                    exact
                                    path={routes.videos}
                                    component={Videos}
                                  />
                                  <Route
                                    exact
                                    path={routes.pia}
                                    component={PIA}
                                  />
                                  <Route
                                    exact
                                    path={routes.extractiveProjects}
                                    component={ExtractiveProjects}
                                  />
                                  <Route
                                    exact
                                    path={routes.corruptionCases}
                                    component={CorruptionCases}
                                  />
                                  <Route
                                    exact
                                    path={routes.africanMiningVision}
                                    component={AfricanMiningVision}
                                  />
                                  <Route
                                    exact
                                    path={routes.infographics}
                                    component={Infographics}
                                  />
                                  {/* <Route exact path={routes.legalFramework} component={LegalFramework} /> */}
                                  <Route
                                    exact
                                    path={routes.policybrief}
                                    component={PolicyBrief}
                                  />
                                  <Route
                                    exact
                                    path={routes.about}
                                    component={About}
                                  />
                                  <Route
                                    exact
                                    path={routes.ogp}
                                    component={Ogp}
                                  />
                                  {/* <Route exact path={routes.otherPublications} component={OtherPublications} /> */}
                                  <Route
                                    exact
                                    path={routes.oilAndGas}
                                    component={OilAndGas}
                                  />
                                  <Route
                                    exact
                                    path={routes.blog}
                                    component={Blog}
                                  />
                                  <Route
                                    exact
                                    path={routes.data}
                                    component={Data}
                                  />
                                  <Route path={routes.page403}>
                                    <Page403 />
                                  </Route>
                                  <Route path={routes.page500}>
                                    <Page500 />
                                  </Route>
                                  <Route exact path={routes.adminLogin}>
                                    <Login />
                                  </Route>
                                  <Route path={routes.admin}>
                                    <Admin />
                                  </Route>
                                  <Route path={`${routes.singleBlog}/:id`}>
                                    <SingleBlog />
                                  </Route>
                                  <Route path={`${routes.innerData}/:id`}>
                                    <InnerData />
                                  </Route>
                                  <Route component={Page404} />
                                </Switch>
                              </PiaContext>
                            </DataContext>
                          </BlogContext>
                        </VideoContext>
                      </UserContext>
                    </PublicationContext>
                  </PolicyContext>
                </LegalContextWrapper>
              </InfographicsWrapper>
            </CommitmentContext>
          </AuthContext>
        </Suspense>
      </div>
      <Route
        exact
        path={[
          routes.home,
          routes.legalFramework,
          routes.about,
          routes.policybrief,
          routes.otherPublications,
          routes.oilAndGas,
          routes.blog,
          routes.ogp,
          routes.empty,
          routes.page404,
          routes.page403,
          routes.page500,
          routes.data,
          routes.infographics,
          routes.videos,
          `${routes.singleBlog}/:id`,

          `${routes.innerData}/:id`,
        ]}
      >
        <Footer />
      </Route>
    </>
  );
}

export default App;
