import React from 'react';
import ReactPaginate from 'react-paginate';


const Pagination = ({
    pageCount,
    previousLabel = '<',
    nextLabel = '>',
    pageRangeDisplayed = 2,
    marginPagesDisplayed = 2,
    breakLabel = '...',
    containerClassName,
    pageClassName,
    activeClassName,
    activeLinkClassName,
    pageLinkClassName,
    breakClassName,
    // forcePage,
    initialPage,
    nextPrevClassName,
    breakLinkClassName,
    disabledNextPrevClassName,
    onPageChange,
}) => {
    return ( <div>
        <ReactPaginate pageCount={pageCount}
        disableInitialCallback={true}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        breakLabel={breakLabel}
        pageClassName={pageClassName}
        activeClassName={activeClassName}
        containerClassName={containerClassName}
        activeLinkClassName={activeLinkClassName}
        pageLinkClassName={pageLinkClassName}
        breakClassName={breakClassName}
        previousLinkClassName={nextPrevClassName}
        nextLinkClassName={nextPrevClassName}
        // forcePage={forcePage}
        initialPage={initialPage}
        disabledClassName={disabledNextPrevClassName}
        breakLinkClassName={breakLinkClassName}
        onPageChange={onPageChange}
        />
    </div> );
}
 
export default Pagination;