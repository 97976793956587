const routes = {
  home: "/",
  about: "/about",
  legalFramework: "/about/legalframework",
  infographics: "/resources/infographics",
  policybrief: "/resources/policybrief",
  otherPublications: "/resources/otherpublications",
  oilAndGas: "/oilandgas",
  blog: "/blog",
  singleBlog: "/blog/singleBlog",
  videos: "/resources/videos",
  ogp: "/progresstracker",
  empty: "/emptypage",
  page404: "/404",
  page403: "/403",
  page500: "/500",
  data: "/data",
  innerData: "/innerdata",
  admin: "/admin",
  adminLogin: "/admin/login",
  adminBlog: "/admin/blog",
  adminBlogList: "/admin/blog/list",
  adminCreateBlog: "/admin/blog/create",
  adminDocument: "/admin/document",
  adminDocumentCategory: "/admin/document/categories",
  adminDocumentFramework: "/admin/document/framework",
  adminDocumentPolicy: "/admin/document/policy",
  adminDocumentPublication: "/admin/document/publication",
  adminOverview: "/admin/overview",
  adminVideo: "/admin/video",
  adminUser: "/admin/users",
  adminInfographics: "/admin/infographics",
  adminData: "/admin/data",
  adminDataSubData: "/admin/data/subdata",
  adminDataCategory: "/admin/data/category",
  adminDataData: "/admin/data/data",
  adminProgressTracker: "/admin/progress",
  adminCommitments: "/admin/progress/commitment",
  pia: "/pia",
  adminPia: "/admin/pia",
  extractiveProjects: "/extractive-projects",
  adminExtractiveProjects: "/admin/extractive-projects",
  corruptionCases: "/corruption-cases",
  adminCorruptionCases: "/admin/corruption-cases",
  africanMiningVision: "/african-mining-vision",
  adminAfricanMiningVision: "/admin/african-mining-vision",
};

export default routes;
