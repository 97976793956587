import React from "react";
import { infographicsReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const InfographicContext = React.createContext();

const InfographicsContextWrapper = ({ children }) => {
  const [infographicState, dispatch] = useThunkReducer(
    infographicsReducer,
    initialState
  );

  const value = {
    infographicState,
    dispatch,
  };
  return (
    <InfographicContext.Provider value={value}>
      {children}
    </InfographicContext.Provider>
  );
};

export default InfographicsContextWrapper;
