import { useState  } from 'react';
import { ReactComponent as Chevron } from "../../../Assets/Icons/chevron-down.svg";
import { ReactComponent as CloseIcon } from "../../../Assets/Icons/close.svg";
import { Link } from 'react-router-dom';

const Sidebar = ({ sideBarTogglerRef, toggleSidebar, navList }) => {

  const [checkedInput, setCheckedInput ] = useState(null);

  const onInputChecked = (e) => {
    const { target } = e;
    if (checkedInput === target?.id) {
      return setCheckedInput(null);
    }
    setCheckedInput(target?.id);
  };
  return (
    <nav className="md:hidden fixed w-full z-30  ">
      <input id="dropdownToggler" type="checkbox" ref={sideBarTogglerRef} />
      <div className="  z-10 bg-white w-full sidebar  relative ">
        <ul className="  flex flex-col font-sans text-sm leading-5 text-chaos lg:text-sm lg:leading-5 w-full h-full overflow-y-auto">
          {navList.map((nav) => {
            if (!nav?.dropdown) {
              return (
                <li
                  onClick={toggleSidebar}
                  key={nav?.title}
                  className=" hover:text-fig cursor-pointer px-6 border-b  first:border-t py-18 border-silver"
                >
                  <Link to={nav?.path}>{nav?.title}</Link>
                </li>
              );
            }
            return (
              <li
                key={nav?.title}
                className=" hover:text-fig cursor-pointer px-6 border-b first:border-t py-18 border-silver "
              >
                <label
                  htmlFor={`${nav?.title}`}
                  className="flex items-center justify-between flex-wrap w-full"
                >
                  <span className="">{nav?.title}</span>

                  <input
                    checked={checkedInput === nav?.title}
                    onChange={onInputChecked}
                    type="checkbox"
                    className="hidden subNavToggler"
                    id={`${nav?.title}`}
                  />
                  <Chevron className="flex-shrink-0 subNavChevron" />
                  <div className="flex-shrink-0 w-full subNav ">
                    <ul className="  mt-18 border-l-1.4 border-fig p-4 ">
                      {nav?.dropdownData?.nav?.map((subNav) => (
                        <li
                          onClick={toggleSidebar}
                          key={subNav?.title}
                          className="mb-6 last:mb-0 uppercase text-chaos hover:text-fig"
                        >
                          <Link to={subNav?.path}>{subNav?.title} </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
        <button
          onClick={toggleSidebar}
          className="absolute bottom-0 h-50 flex items-center justify-between bg-lynx w-full  px-6 font-sans text-chaos text-sm leading-5"
        >
          <span>CLOSE</span>
          <CloseIcon />
        </button>
      </div>
    </nav>
  );
};

export default Sidebar;
