import Showcase from "./Showcase";
import LatestInsight from './LatestInsights';
import FocusArea from './FocusArea';
import About from './About';
import Infographics from './Infographics';
import ExtractiveData from './ExtrativeData';



const HomePage = () => {
  return (
    <div>
      <Showcase />
      <LatestInsight />
      <About/>
      <ExtractiveData />
      <FocusArea />
      <Infographics />
    </div>
  );
};

export default HomePage;
