import React from "react";
import { publicationReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const PublicationContext = React.createContext();

const PublicationContextWrapper = ({ children }) => {
  const [publicationState, dispatch] = useThunkReducer(
    publicationReducer,
    initialState
  );

  const value = {
    publicationState,
    dispatch,
  };
  return (
    <PublicationContext.Provider value={value}>
      {children}
    </PublicationContext.Provider>
  );
};

export default PublicationContextWrapper;
