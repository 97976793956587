import axios from 'axios';
import cache, { getCacheKey } from './cache';
import ls, { USER_NOR_GET_SHI_SHI } from './utils/ls';


const instance = axios.create({
    baseURL: process.env.REACT_APP_DEV_URL
});

instance.interceptors.request.use(function (request) {
    const { method, url, params = {} } = request;
    if((method === 'get' || method === 'GET') && !params?.noCache) {
      const cacheKey = getCacheKey({
        url,
        ...params
      });
      const data = cache.get(cacheKey);
      if(data) {
        request.headers.cached = true;
        request.data = data;
        return Promise.reject(request)
      }
      return request
    }
    return request;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const { config } = response;
      if (config.method === "GET" || config.method === "get") {
        const { url, params } = config;
        const cacheKey = getCacheKey({
          url,
          ...params,
        });
        cache.set(cacheKey, response?.data);
      }
      return response;
    },
    function (error) {

      if(error?.response?.status === 401) {
        ls.delete(USER_NOR_GET_SHI_SHI)
        window.location.reload()
      }
      if (error?.headers?.cached) {
        return Promise.resolve(error);
      }
      return Promise.reject(error);
    }
  );


const createApiService = ({ endpoint, method, options = {} }) => {
  switch (method) {
    case "post":
      return (params) => instance.post(endpoint, params, options);
    case "put":
      return (params) => instance.put(endpoint, params, options);
    case "delete":
      return () => instance.delete(endpoint, options);

    default:
      return () => instance.get(endpoint, options);
  }
};

export default createApiService;
