export const USER_NOR_GET_SHI_SHI = 'USER_NOR_GET_SHI_SHI';

const ls = {
    store: (key, value) => localStorage.setItem(key, value),
    get: (key) => localStorage.getItem(key),
    delete: (key) => localStorage.removeItem(key),
};


export default ls;
