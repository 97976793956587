import PrimaryButton from "../../Commons/Button/PrimaryButton";
import InsightCard from "./InsightCard";
import { useHistory } from "react-router-dom";
import routes from "../../utils/routes";

const InsightList = ({ data }) => {
  const { push } = useHistory();
  const seeMoreHandler = () => push(routes.blog);

  return (
    <div className="flex flex-wrap w-full justify-center">
      {data?.map((data, index) => (
        <InsightCard key={index} blog={data} />
      ))}
      <div className="w-full flex items-center justify-center">
        <PrimaryButton
          onClick={seeMoreHandler}
          variant="primary"
          classes="mt-6  sm:mt-12  rounded text-white text-lg font-medium leading-27 inline-block w-full md:w-auto lg:mt-14"
        >
          <span className="inline-block py-3 md:px-8">See More</span>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default InsightList;
