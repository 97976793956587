export const FETCH_INFOGRAPHICS = 'FETCH_INOFGRAPHICS';
export const FETCH_INFOGRAPHICS_SUCCESS = 'FETCH_INFOGRAPHICS_SUCCESS';
export const FETCH_INFOGRAPHICS_FAILED = 'FETCH_INFOGRAPHICS_FAILED';

export const ADD_INFOGRAPHICS = 'ADD_INFOGRAPHICS';
export const ADD_INFOGRAPHICS_FAILED = 'ADD_INFOGRAPHICS_FAILED';
export const ADD_INFOGRAPHICS_SUCCESS = "ADD_INFOGRAPHICS_SUCCESS";

export const DELETE_INFOGRAPHICS = 'DELETE_INFOGRAPHICS';
export const DELETE_INFOGRAPHICS_COMPLETE = 'DELETE_INFOGRAPHICS_COMPLETE';
export const DELETE_INFOGRAPHICS_FAILED = 'DELETE_INFOGRAPHICS_FAILED';
