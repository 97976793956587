export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const ADD_USER = "ADD_USER";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_SUCCESS = "DELETE_LEGAL_CATEGORY_SUCCESS";


