export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_FAILED = 'FETCH_BLOG_FAILED';

export const ADD_BLOG = 'ADD_BLOG';
export const ADD_BLOG_FAILED = 'ADD_BLOG_FAILED';
export const ADD_BLOG_SUCCESS = 'ADD_BLOG_SUCCESS';

export const PUBLISH_BLOG = 'PUBLISH_BLOG';
export const PUBLISH_BLOG_COMPLETE = 'PUBLISH_BLOG_COMPLETE';
export const PUBLISH_BLOG_FAILED = 'PUBLISH_BLOG_FAILED';

export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILED = 'DELETE_BLOG_FAILED';

export const UPDATE_BLOG = 'UPDATE_BLOG';
export const UPDATE_BLOG_FAILED = 'UPDATE_BLOG_FAILED';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
