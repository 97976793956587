import clsx from "clsx";
import PropTypes from "prop-types";

const PrimaryButton = ({
  children,
  classes = "",
  onClick = () => {},
  type = "button",
  variant,
  disabled,
  form,
}) => {
  return (
    <span
      className={clsx({
        "cursor-pointer overflow-hidden": true,

        [classes]: true,
      })}
    >
      <button
      form={form}
      disabled={disabled}
        onClick={onClick}
        type={type}
        className={clsx({
          "w-full outline-none focus:outline-none h-full cursor-pointer": true,
          "bg-fig hover:bg-mulberry transition duration-300 ease-in-out disabled:bg-errigal rounded text-white":
            variant === "primary",
          "bg-secondaryBtn rounded hover:bg-mulberry transition duration-300 ease-in-out disabled:bg-errigal text-fig hover:text-white":
            variant === "secondary",
        })}
      >
        {children}
      </button>
    </span>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default PrimaryButton;
