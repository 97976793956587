export const ADD_DATA_CATEGORY = 'ADD_DATA_CATEGORY';
export const ADD_DATA_CATEGORY_FAILED = 'ADD_DATA_CATEGORY_FAILED';
export const ADD_DATA_CATEGORY_SUCCESS = 'ADD_DATA_CATEGORY_SUCCESS';

export const ADD_DATASET = 'ADD_DATASET';
export const ADD_DATASET_FAILED = 'ADD_DATASET_FAILED';
export const ADD_DATASET_SUCCESS = 'ADD_DATASET_SUCCESS';

export const DELETING_DATA_CATEGORY = 'DELETE_DATA_CATEGORY';
export const DELETE_DATA_CATEGORY_COMPLETE = 'DELETE_DATA_CATEGORY_COMPLETE';

export const UPDATING_DATA_CATEGORY = 'UPDATING_DATA_CATEGORY';
export const UPDATING_DATA_CATEGORY_COMPLETE = 'UPDATING_DATA_CATEGORY_COMPLETE';

export const DELETE_SUB_DATA_CATEGORY = 'DELETE_SUB_DATA_CATEGORY';
export const DELETE_SUB_DATA_CATEGORY_COMPLETE = 'DELETE_SUB_DATA_CATEGORY_COMPLETE';

export const ADD_DATA_SUB_CATEGORY = 'ADD_DATA_SUB_CATEGORY';
export const ADD_DATA_SUB_CATEGORY_COMPLETE = 'ADD_DATA_SUB_CATEGORY_COMPLETE';

export const UPDATE_DATA_SUB_CATEGORY = 'UPDATE_DATA_SUB_CATEGORY';
export const UPDATE_DATA_SUB_CATEGORY_COMPLETE = 'UPDATE_DATA_SUB_CATEGORY_COMPLETE';

export const DELETE_DATASET = 'DELETE_DATASET';
export const DELETE_DATASET_COMPLETE = 'DELETE_DATASET_COMPLETE';
