import { includes } from "lodash";
import {
  FETCH_LEGAL_DOCUMENT,
  FETCH_LEGAL_DOCUMENT_FAILED,
  FETCH_LEGAL_DOCUMENT_SUCCESS,

  FETCH_LEGAL_POLICY_CATEGORY,
  FETCH_LEGAL_POLICY_CATEGORY_FAILED,
  FETCH_LEGAL_POLICY_CATEGORY_SUCCESS,

  ADD_LEGAL_CATEGORY,
  ADD_LEGAL_CATEGORY_FAILED,
  ADD_LEGAL_CATEGORY_SUCCESS,

  EDIT_LEGAL_CATEGORY,
  EDIT_LEGAL_CATEGORY_FAILED,
  EDIT_LEGAL_CATEGORY_SUCCESS,

  DELETE_LEGAL_CATEGORY,
  DELETE_LEGAL_CATEGORY_FAILED,
  DELETE_LEGAL_CATEGORY_SUCCESS,

  ADD_LEGAL_FRAMEWORK,
  ADD_LEGAL_FRAMEWORK_FAILED,
  ADD_LEGAL_FRAMEWORK_SUCCESS,

  DELETE_LEGAL_FRAMEWORK,
  DELETE_LEGAL_FRAMEWORK_FAILED,
  DELETE_LEGAL_FRAMEWORK_SUCCESS,


} from "./types";

export const initialState = {
  fetchingLegalPolicy: false,
  addingCategory: false,
  addingLegalPolicy: false,
  deletingLegalPolicy: false,
  deletingCategory: false,
  legalPolicyToDelete: [],
  categoryToDelete: [],
  editingCategory: false,
  fetchingCategory: false,
  legalCategory: [],
  legalPolicy: {
    data: []
  },
};

export const legalReducer = (state = initialState, action) => {
  switch (action?.type) {

    case DELETE_LEGAL_FRAMEWORK: return {
      ...state,
      deletingLegalPolicy: true,
      legalPolicyToDelete: action?.ids
    }
    case DELETE_LEGAL_FRAMEWORK_FAILED: return {
      ...state,
      deletingLegalPolicy: false,
      legalPolicyToDelete: []
    }
    case DELETE_LEGAL_FRAMEWORK_SUCCESS: {
      return {
      ...state,
      deletingLegalPolicy: false,
      legalPolicyToDelete: [],
      legalPolicy: {
        ...state.legalPolicy,
        data: state.legalPolicy.data.filter((datum) => !includes(action?.ids, datum?.id)),
        total: Number(state.legalPolicy.total) - 1

      }
    }
  }
    case ADD_LEGAL_FRAMEWORK: return {
      ...state,
      addingLegalPolicy: true,
    }
    case ADD_LEGAL_FRAMEWORK_FAILED: return {
      ...state,
      addingLegalPolicy: false,
    }
    case ADD_LEGAL_FRAMEWORK_SUCCESS: return {
      ...state,
      addingLegalPolicy: false,
      legalPolicy: {
        ...state?.legalPolicy,
        data: [action?.framework, ...state?.legalPolicy?.data]
      }
    }
    case DELETE_LEGAL_CATEGORY: return {
      ...state,
      deletingCategory: true,
      categoryToDelete: [...state?.categoryToDelete, action?.id]
    };
    case DELETE_LEGAL_CATEGORY_FAILED: return {
      ...state,
      deletingCategory: false,
      categoryToDelete: [],
    }
    case DELETE_LEGAL_CATEGORY_SUCCESS: return {
      ...state,
      deletingCategory: false,
      categoryToDelete: [],
      legalCategory: state?.legalCategory?.filter((category) => category?.id !== action?.category?.id)
    }
    case ADD_LEGAL_CATEGORY: return {
      ...state,
      addingCategory: true
    }
    case ADD_LEGAL_CATEGORY_FAILED: return {
      ...state,
      addingCategory: false,
    }
    case ADD_LEGAL_CATEGORY_SUCCESS: return {
      ...state,
      addingCategory: false,
      legalCategory: [action?.category, ...state.legalCategory]
    }
    case FETCH_LEGAL_DOCUMENT:
      return {
        ...state,
        fetchingLegalPolicy: true,
      };
    case FETCH_LEGAL_DOCUMENT_FAILED:
      return {
        ...state,
        fetchingLegalPolicy: false,
      };
    case FETCH_LEGAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchingLegalPolicy: false,
        legalPolicy: action?.legalPolicy,
      };
    case FETCH_LEGAL_POLICY_CATEGORY:
      return {
        ...state,
        fetchingCategory: true,
      };
    case FETCH_LEGAL_POLICY_CATEGORY_FAILED:
      return {
        ...state,
        fetchingCategory: false,
      };
    case FETCH_LEGAL_POLICY_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingCategory: false,
        legalCategory: action?.category,
      }
    case EDIT_LEGAL_CATEGORY: return {
      ...state,
      editingCategory: true,

    };
    case EDIT_LEGAL_CATEGORY_FAILED: return {
      ...state,
      editingCategory: false,
    }
    case EDIT_LEGAL_CATEGORY_SUCCESS: return {
      ...state,
      editingCategory: false,
      legalCategory: state?.legalCategory?.map(( category ) => {
        if(category?.id === action?.category?.id) {
          return action?.category
        }
        return category;
      }) 
    }
    default:
      return state;
  }
};
