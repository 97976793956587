import clsx from "clsx";
import { useHistory, Link } from "react-router-dom";

const DesktopDropdownList = ({
  dropdownRef,
  closeDropdown,
  dropdownData,
  activeBanner,
  setActiveBanner,
}) => {
  const {
    location: { pathname },
  } = useHistory();

  return (
    <>
      <input id="dropdownToggler" type="checkbox" ref={dropdownRef} />
      <div
        onMouseLeave={closeDropdown}
        className={clsx({
          "hidden md:block md:fixed bg-white w-full dropdown h-400 z-30 shadow-showcase ": true,
          "md:mt-58 lg:mt-74 hidden md:block ": true,
        })}
      >
        <div className="px-6 md:px-12 lg:w-78% xl:w-1200 xl:px-12 lg:mx-auto lg:px-0 h-full">
          <div className="w-full flex h-full p-6">
            <div
              style={{
                backgroundImage: `url(${activeBanner})`,
                backgroundSize: "cover",
              }}
              className="bg-chaos w-1/2 h-full mr-8 transition"
            ></div>
            <ul className="pt-10 text-chaos capitalize">
              {!!dropdownData?.nav &&
                dropdownData?.nav?.map((navItem) => {
                  return (
                    <li
                      key={navItem?.title}
                      onClick={closeDropdown}
                      onMouseEnter={() => setActiveBanner(navItem?.banner)}
                      className={clsx({
                        "pb-4 last:pb-0  text-base leading-6 font-sans capitalize": true,
                        "text-fig": pathname === navItem?.path,
                      })}
                    >
                      <Link to={navItem?.path}>{navItem?.title}</Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopDropdownList;
