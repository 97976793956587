import React from "react";
import { authReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const AuthContext = React.createContext();

const AuthWrapper = ({ children }) => {
  const [authState, dispatch] = useThunkReducer(authReducer, initialState);

  const value = {
    authState,
    dispatch,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthWrapper;
