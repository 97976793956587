import { ReactComponent as GraphIcon } from "../../Assets/Icons/Union.svg";
import { reduce } from "lodash";
import { Link } from "react-router-dom";
import routes from "../../utils/routes";
import { ReactComponent as ArrowRightIcon } from "../../Assets/Icons/right_arrow.svg";
import numeral from "numeral";

numeral.reset();
numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "Billion",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});

numeral.locale("fr");

const DataCard = ({ data }) => {
  const getSumOfData = (value) => {
    const { FixOurOilDataSets } = value || [];

    // const { FixOurOilDataSets } = fixouroil || {};
    const sum = reduce(
      FixOurOilDataSets,
      (sum, data) => {
        return sum + data.FixOurOilDataValues_aggregate.aggregate.sum.value;
      },
      0
    );
    return sum;
  };
  return (
    <div className="mb-5 h-314 bg-transparent w-full md:w-1/2 lg:w-1/4 px-10 py-9 flex  items-start overflow-hidden justify-center flex-col cursor-pointer md:odd:border-r  lg:border-r lg:last:border-0 border-wayward dataWrapper">
      <Link to={`${routes.innerData}/${data.id}`}>
        <div>
          <GraphIcon />
        </div>
      </Link>
      <Link to={`${routes.innerData}/${data.id}`}>
        <div className="mt-4">
          <h6 className="text-sm leading-21 text-chaos">{data.name}</h6>
          <h5 className="text-2xl font-bold text-fig leading-9">
            <span className="text-xs font-normal">over</span>{" "}
            {numeral(getSumOfData(data)).format("0 a")}{" "}
            <span className="text-xs font-normal">{data.metrics}</span>
          </h5>
          <span className="text-xs text-slate font-normal">Since 2015</span>
        </div>
      </Link>
      <div className="w-full border-mustard border-t mt-10 pt-4 lookData">
        <Link to={`${routes.innerData}/${data.id}`}>
          <span className="text-base text-mustard flex items-center justify-between w-full">
            <span className=""> Look at the data</span>
            <ArrowRightIcon />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default DataCard;
