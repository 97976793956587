import { includes } from "lodash";

import {
  FETCH_PUBLICATION,
  FETCH_PUBLICATION_FAILED,
  FETCH_PUBLICATION_SUCCESS,

  ADD_PUBLICATION,
  ADD_PUBLICATION_FAILED,
  ADD_PUBLICATION_SUCCESS,

  DELETE_PUBLICATION,
  DELETE_PUBLICATION_FAILED,
  DELETE_PUBLICATION_SUCCESS,
} from "./types";

export const initialState = {
  fetchingPublications: false,
  addingPublication: false,
  publicationsToDelete: [],
  deletingPublications: [],
  publications: {
    data: []
  },
};

export const publicationReducer = (state = initialState, action) => {
  switch (action?.type) {

    case DELETE_PUBLICATION : return {
      ...state,
      deletingPublications: true,
      publicationsToDelete: action?.ids
    }
    case DELETE_PUBLICATION_FAILED: return {
      ...state,
      deletingPublications: false,
      publicationsToDelete: []
    }
    case DELETE_PUBLICATION_SUCCESS: {
      return {
      ...state,
      deletingPublications: false,
      publicationsToDelete: [],
      publications: {
        ...state.publications,
        data: state.publications.data.filter((datum) => !includes(action?.ids, datum?.id)),
        total: Number(state.publications.total) - 1
      }
    }
  }


    case ADD_PUBLICATION: return {
      ...state,
      addingPublication: true,
    };
    case ADD_PUBLICATION_FAILED: return {
      ...state,
      addingPublication: false,
    }
    case ADD_PUBLICATION_SUCCESS: return {
      ...state,
      addingPublication: false,
      publications: {
        ...state?.publications,
        data: [action?.publication, ...state?.publications?.data]
      }
    }
    case FETCH_PUBLICATION:
      return {
        ...state,
        fetchingPublications: true,
      };
    case FETCH_PUBLICATION_FAILED:
      return {
        ...state,
        fetchingPublications: false,
      };
    case FETCH_PUBLICATION_SUCCESS:
      return {
        ...state,
        fetchingPublications: false,
        publications: action?.publication,
      };
    default:
      return state;
  }
};
