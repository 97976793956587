import PrimaryButton from '../../Commons/Button/PrimaryButton';
import { ReactComponent as PlayIcon } from '../../Assets/Icons/play.svg';
import './showcase.css';
import { ReactComponent as MapSection } from '../../Assets/Imgs/africa_side.svg';
import useModal from "../../HOC/useModal";
import ModalPlayer from "../../Commons/Player/ModalPlayer";

// import { ReactComponent as MapTrace } from '../../Assets/Imgs/maptrace.svg';

const Showcase = () => {
  const { modal, openModal, closeModal } = useModal({
    backgroundClose: true
  })

  const playVideo = (e) => {
    e.preventDefault();
    openModal(<ModalPlayer onCancel={closeModal} video={{
      file_url: "https://youtu.be/q4HwhJKjKPI"
    }} />)
  }
  return (
    <>
    {modal}
    <div className=" px-6  md:px-12 md:h-473 md:flex items-center md:bg-right-top  lg:px-0  lg:h-712 bg-f relative ">

      <div className="w-full flex items-center lg:w-78% lg:mx-auto xl:w-1200 xl:px-12  z-20 showcase_content">
        <div className="py-8 font-sans md:w-1/2 lg:w-407 xl:w-5/12">
          <h6 className="text-5xl font-bold text-fig leading-72 tracking-wide lg:text-68  lg:leading-102">
            FIX OUR OIL
          </h6>
          <p className="mt-2 leading-7 font-sans text-sm text-chaos md:text-base lg:w-407">
            The Nigerian oil & gas industry has historically been used to pander
            to the needs of very few highly placed in the society.
          </p>
          <PrimaryButton onClick={playVideo} classes="mt-6 inline-block w-full bg-blackout rounded sm:w-157">
            <span className="text-white flex items-center w-full justify-center  leading-6 py-2 font-medium">
              <PlayIcon />
              <span className="ml-2">Play Video</span>
            </span>
          </PrimaryButton>
        </div>
      </div>
      <div className="absolute top-0  right-0 bottom-0 left-0 flex justify-end overflow-hidden -z-1 bg-white md:bg-shocking">
        <MapSection className="hidden sm:block lg:hidden " />
        <img src="https://res.cloudinary.com/budgit/image/upload/q_auto:low/v1622806068/FIXOUROIL/Homepage_map_vjumrl.jpg" className="hidden lg:block w-full h-full object-fill" alt="bg" />
      </div>
    </div>
    </>
  );
};

export default Showcase;