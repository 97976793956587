export const FETCH_POLICY = 'FETCH_INOFGRAPHICS';
export const FETCH_POLICY_SUCCESS = 'FETCH_POLICY_SUCCESS';
export const FETCH_POLICY_FAILED = 'FETCH_POLICY_FAILED';

export const ADD_POLICY = 'ADD_POLICY';
export const ADD_POLICY_FAILED = 'ADD_POLICY_FAILED';
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS';

export const DELETE_POLICY = 'DELETE_POLICY';
export const DELETE_POLICY_FAILED = 'DELETE_POLICY_FAILED';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
