import routes from "../../utils/routes";

const footerNavList = [
  {
    nav: [
      {
        title: "ABOUT US",
        path: routes.about,
      },
      // {
      //   title: "#Fix Our Oil",
      // },
      // {
      //   title: "Overview Data",
      // },
      // {
      //   title: "The Forum",
      // },
      // {
      //   title: "Testimonials",
      // },
    ],
  },

  {
    nav: [
      {
        title: "PROGRESS TRACKER",
        path: routes.ogp,
      },
      // {
      //   title: "ETIT",
      // },
      {
        title: "OGP",
        path: routes.ogp,

      },
      {
        title: "PIA",
        path: routes.pia,

      },
      // {
      //   title: "BER",
      // },
    ],
  },
  {
    heading:    "INSIGHTS",
    nav: [

      {
        title: "Policy Briefs",
        path: routes.policybrief,
      },
      {
        title: "Data Dashboard",
        path: routes.data

      },
      {
        title: "Infographics",
        path: routes.infographics,
      },
      {
        title: "Interactive Videos",
        path: routes.videos,
      },
    ],
  },
];

export default footerNavList;
