import { useRef } from "react";
import { ReactComponent as DownloadIcon } from "../Assets/Icons/download2.svg";
import shortenText from "../utils/shortenText";
import { ReactComponent as DeleteIcon } from "../Assets/Icons/Delete All.svg";

const InfographicCard = ({ infographic, shouldDelete = false, onDelete }) => {
  const thubmnailUrl = new URL(
    infographic?.thumbnail ?? infographic?.file_name
  );
  const fileUrl = new URL(infographic?.file_name ?? infographic?.thumbnail);

  const formEl = useRef();

  const downloadInfographics = () => {
    formEl.current.submit();
  };

  const deleteHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(infographic);
  };

  return (
    <>
      <form
        data-hidden
        onClick={downloadInfographics}
        ref={formEl}
        target="_blank"
        method="get"
        action={fileUrl}
        className="h-full w-full  relative  flex flex-col mb-10 "
      >
        <aside className="h-4/5 w-full shadow-info bg-silver relative flex-shrink-0">
          <img
            className="object-cover h-full w-full"
            alt={infographic?.title}
            src={thubmnailUrl}
          />
          <div className=" w-full absolute top-0 left-0 right-0 bottom-0 bg-infocard flex items-end">
            <div className="px-4 py-6 text-white flex items-end justify-between text-base font-sans font-bold leading-8 w-full md:leading-6 lg:text-2xl	lg:leading-8">
              <span className="flex-grow flex-shrink-0"></span>
              {!shouldDelete && (
                <button type="submit" className="flex-shrink-0 mr-4">
                  <DownloadIcon />
                </button>
              )}
              {shouldDelete && (
                <button
                  onClick={deleteHandler}
                  type="button"
                  className="flex-shrink-0 mr-4 text-mulberry"
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        </aside>
        <div className="mt-6 flex items-end justify-between text-base font-sans font-bold leading-8 w-full md:leading-6 lg:text-base	lg:leading-8 text-ebony">
          {shortenText(infographic?.title, 100)}
        </div>
      </form>
    </>
  );
};

export default InfographicCard;
