import {
 FETCH_VIDEO,
 FETCH_VIDEO_FAILED,
 FETCH_VIDEO_SUCCESS,

  ADD_VIDEO,
  ADD_VIDEO_FAILED,
  ADD_VIDEO_SUCCESS,

  DELETE_VIDEO,
  DELETE_VIDEO_FAILED,
  DELETE_VIDEO_SUCCESS,
} from "./types";
import { includes } from 'lodash'

export const initialState = {
  fetchingVideos: false,
  addingVideo: false,
  videos: {
    data: [],
  },
  deletingVideo: false,
  videoDeleted : [],
};

export const videoReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_VIDEO: return {
      ...state,
      addingVideo: true
    };
    case ADD_VIDEO_FAILED: return {
      ...state,
      addingVideo: false,
    };
    case ADD_VIDEO_SUCCESS: return {
      ...state,
      addingVideo: false,
      videos: {
        ...state?.videos,
        data: [action?.video, ...state?.videos?.data]
      }
    };
    case FETCH_VIDEO:
      return {
        ...state,
        fetchingVideos: true,
      };
    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        fetchingVideos: false,
        videos: action?.videos,
      };
    case FETCH_VIDEO_FAILED:
      return {
        ...state,
        fetchingVideos: false,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        deletingVideo: true,
        videoDeleted: [...state?.videoDeleted, action?.videoIds],
      };
    case DELETE_VIDEO_FAILED: 
      return {
        ...state,
        deletingVideo: false,
        videoDeleted: [],
      };
    case DELETE_VIDEO_SUCCESS: {
      const updatedVideo = state?.videos?.data?.filter((data) => !includes(action?.videoIds, data?.id ))
      // console.log(videos)
      return {
        ...state,
        deletingVideo: false,
        videoDeleted: [],
        videos: {
          ...state?.videos,
          data: updatedVideo,
          total: Number(state.videos.total) - 1

        },
      }
    }
;
    default:
      return state;
  }
};
