import DataCard from './DataCard';
import { GET_TRENDING_CATEGORY_DATA} from '../../Apollo/Queries';
import { useQuery } from "@apollo/client";
import './style.css';

const ExtractiveData = () => {

  const { loading, data: { FixOurOilDataCategories = []} = {}   } = useQuery(GET_TRENDING_CATEGORY_DATA);

  
  return (
    <>
   { !loading && !!FixOurOilDataCategories.length &&  <div className=" font-sans bg-lynx">
      <div className="px-6 py-8 text-chaos lg:w-78% md:px-12 lg:mx-auto lg:px-0 xl:w-1200 xl:px-12 md:py-12 lg:py-88">
        <h3 className="text-2 text-center leading-12 font-medium text-chinese">
          ExtractiveData
        </h3>
        <div className="mt-6 md:mt-10 lg:mt-14 flex flex-wrap">
          {FixOurOilDataCategories.map((data) => {
            return  <DataCard key={data.id} data={data} />;
          })}
        </div>
      </div>
    </div>}
    </>
  );
};

export default ExtractiveData;
