const shortenText = (text, maxLength) => {
    let functText = text;
    if(!functText) return null;
    if (functText?.length <= maxLength) {
      return functText;
    }
    let shortenedString = functText.substr(0, maxLength);
    shortenedString = shortenedString.substr(0, Math.min(shortenedString.length, shortenedString.lastIndexOf(" ")));
    return `${shortenedString}...`
  };
  
  
  export default shortenText;
  
  