import { ReactComponent as NoDataIcon } from '../../Assets/Icons/nodata.svg';

const NoData = ({ caption, msg, icon }) => {
  return (
    <div className="h-full w-full flex items-center justify-center flex-col py-8 font-poppins ">
      <div className="md:w-177 md:h-177 mb-6">
        {icon || <NoDataIcon />}
      </div>
      <h6 className="font-bold mt-5 text-5xl   leading-72   md:text-68 md:leading-102 lg:text-2rem text-slate">
        {caption || "Sorry"}
      </h6>
      <div>
        <p className="text-center text-sm leading-snug text-chaos md:text-base md:leading-8 lg:text-base lg:leading-34">
          {msg ||
            <span>We currently do not have any data on this at the moment. <br/> Please check back later.</span> }
        </p>
      </div>
    </div>
  );
};
 
export default NoData;