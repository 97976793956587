import React from "react";
import { policyReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const PolicyContext = React.createContext();

const PolicyContextWrapper = ({ children }) => {
  const [policyState, dispatch] = useThunkReducer(
    policyReducer,
    initialState
  );

  const value = {
    policyState,
    dispatch,
  };
  return (
    <PolicyContext.Provider value={value}>
      {children}
    </PolicyContext.Provider>
  );
};

export default PolicyContextWrapper;
