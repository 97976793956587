export const FETCH_LEGAL_DOCUMENT = 'FETCH_LEGAL_DOCUMENT';
export const FETCH_LEGAL_DOCUMENT_FAILED = 'FETCH_LEGAL_DOCUMENT_FAILED';
export const FETCH_LEGAL_DOCUMENT_SUCCESS = 'FETCH_LEGAL_DOCUMENT_SUCCESS';

export const FETCH_LEGAL_POLICY_CATEGORY = 'FETCH_LEGAL_POLICY_CATEGORY';
export const FETCH_LEGAL_POLICY_CATEGORY_FAILED = 'FETCH_LEGAL_POLICY_CATEGORY_FAILED';
export const FETCH_LEGAL_POLICY_CATEGORY_SUCCESS = 'FETCH_LEGAL_POLICY_CATEGORY_SUCCESS';

export const ADD_LEGAL_CATEGORY = 'ADD_LEGAL_CATEGORY';
export const ADD_LEGAL_CATEGORY_FAILED = 'ADD_LEGAL_CATEGORY_FAILED';
export const ADD_LEGAL_CATEGORY_SUCCESS = 'ADD_LEGAL_CATEGORY_SUCCESS';

export const DELETE_LEGAL_CATEGORY = 'DELETE_LEGAL_CATEGORY';
export const DELETE_LEGAL_CATEGORY_FAILED = 'DELETE_LEGAL_CATEGORY_FAILED';
export const DELETE_LEGAL_CATEGORY_SUCCESS = 'DELETE_LEGAL_CATEGORY_SUCCESS';

export const EDIT_LEGAL_CATEGORY = 'EDIT_LEGAL_CATEGORY';
export const EDIT_LEGAL_CATEGORY_FAILED = 'EDIT_LEGAL_CATEGORY_FAILED';
export const EDIT_LEGAL_CATEGORY_SUCCESS = 'EDIT_LEGAL_CATEGORY_SUCCESS';

export const ADD_LEGAL_FRAMEWORK = 'ADD_LEGAL_FRAMEWORK';
export const ADD_LEGAL_FRAMEWORK_FAILED = 'ADD_LEGAL_FRAMEWORK_FAILED';
export const ADD_LEGAL_FRAMEWORK_SUCCESS = 'ADD_LEGAL_FRAMEWORK_SUCCESS';

export const DELETE_LEGAL_FRAMEWORK = 'DELETE_LEGAL_FRAMEWORK';
export const DELETE_LEGAL_FRAMEWORK_SUCCESS = 'DELETE_LEGAL_FRAMEWORK_SUCCESS';
export const DELETE_LEGAL_FRAMEWORK_FAILED = 'DELETE_LEGAL_FRAMEWORK_FAILED';
