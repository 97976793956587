import React from "react";
import { userReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const UserContext = React.createContext();

const UserContextWrapper = ({ children }) => {
  const [userState, dispatch] = useThunkReducer(userReducer, initialState);

  const value = {
    userState,
    dispatch,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextWrapper;
