import {
  ADD_BLOG,
  ADD_BLOG_FAILED,
  ADD_BLOG_SUCCESS,
  FETCH_BLOG,
  FETCH_BLOG_FAILED,
  FETCH_BLOG_SUCCESS,

  PUBLISH_BLOG,
  PUBLISH_BLOG_COMPLETE,
  PUBLISH_BLOG_FAILED,

  DELETE_BLOG,
  DELETE_BLOG_FAILED,
  DELETE_BLOG_SUCCESS,

  UPDATE_BLOG,
  UPDATE_BLOG_FAILED,
  UPDATE_BLOG_SUCCESS,
} from "./types";
import { updateBlogService, fetchBlogService, fetchSingleBlog, addBlogService, publishBlogService, deleteBlogService } from "./api";
import { toast } from "react-toastify";


export const updateBlogAction = ({ token, params }) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_BLOG})
    const formData = new FormData();
    formData.append("title", params?.title);
    formData.append("description", params?.description);
    formData.append("body", params?.body);
    formData.append("file", params?.file);

    const response = await updateBlogService(token, params.id)(formData);
    dispatch({type: UPDATE_BLOG_SUCCESS, data: response.data})
    toast.success("Update Successful");
    return true
  } catch (error) {
   dispatch({type: UPDATE_BLOG_FAILED})
   toast.error(error?.response?.data?.message || "Update Failed");
  }
}
export const deleteBlogAction =
  ({ token, params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: DELETE_BLOG, ids: params?.blogIds });
      await deleteBlogService(token, params)();
      dispatch({ type: DELETE_BLOG_SUCCESS, ids: params?.blogIds });
      toast.success("Delete Successful");
      return true
    } catch (error) {
      dispatch({ type: DELETE_BLOG_FAILED, ids: params?.blogIds});
      toast.error(error?.response?.data?.message || "Delete Failed");
    }
  };

  export const fetchSingleBlogAction =  (id) => async () =>{
    try {
      const response = await fetchSingleBlog(id)();
      return response?.data
    } catch (error) {
      // console.log(error, 'coult not fetch blog')
    }
  }
export const addBlogAction =
  ({ token, params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_BLOG });
      const formData = new FormData();
      formData.append("title", params?.title);
      formData.append("description", params?.description);
      formData.append("body", params?.body);
      formData.append("file", params?.file);
      const response = await addBlogService(token)(formData);
      dispatch({ type: ADD_BLOG_SUCCESS, blog: response?.data });
      toast.success("Blog Created Successfully");
      return true;
    } catch (error) {
      dispatch({ type: ADD_BLOG_FAILED });
      toast.error("Create Blog Failed");
    }
  };

export const fetchBlogAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BLOG });
    const response = await fetchBlogService(params)();
    dispatch({
      type: FETCH_BLOG_SUCCESS,
      blogs: response?.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_BLOG_FAILED });
  }
};
export const fetchPopularBlogAction = (params) => async (dispatch) => {
  try {
    const response = await fetchBlogService(params)();
    return response?.data?.data;
  } catch (error) {
  return;
  }
};

export const publishBlogAction = ({token, params}) => async (dispatch) => {
  try {
    dispatch({type: PUBLISH_BLOG, id: params?.blogId});
     await publishBlogService(token)(params);
    dispatch({type: PUBLISH_BLOG_COMPLETE, id: params?.blogId, published: params?.publish})
    toast.success('Blog Published');
  } catch (error) {
    toast.error('Publish failed')
    dispatch({type: PUBLISH_BLOG_FAILED, id: params?.blogId});
  }
}
