import { ApolloClient, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

const typePolicies = {
  Query: {
    fields: {
      FixOurOilCommitments: {
        ...offsetLimitPagination(),
        read(existing, { args }) {
          return (
            existing && existing.slice(args.offset, args.offset + args.limit)
          );
        },
      },
    },
  },
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_HASURA,
  cache: new InMemoryCache({
    typePolicies,
  }),
  headers: {
    "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
  },
});

export default client;
