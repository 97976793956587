import {ReactComponent as Icon500} from '../Assets/Icons/Oil illustration.svg'
import {useHistory} from 'react-router-dom';


const Page500 = () => {
    const history = useHistory()
    return (
         <div className="px-6 md:px-12 lg:px-0 lg:w-78% lg:mx-auto xl:w-1200 xl:px-12 pt-10 md:pt-12 lg:pt-20">
            <div className="py-10 md:py-12 lg:py-20">
                <div className="flex justify-center items-center"><span><Icon500 /></span></div>
                <h1 className="font-sans font-bold text-5xl md:text-68 leading-72 md:leading-102 not-italic text-center pt-6 md:pt-4 lg:pt-6 text-slate">500</h1>
                <p className="font-sans text-center text-sm md:text-base leading-21 md:leading-8 text-chaos">Oops!, Looks like something is wrong on our end.</p>
                <div className="bg-fig md:bg-transparent flex items-center justify-center rounded mt-72b">
                    <button className="text-white bg-fig font-sans text-lg leading-27 py-3 px-8 rounded font-medium text-center border-none outline-none" onClick={() => history.push('/')}>Go Home</button>
                </div>
            </div>
         </div>
    )
}
 export default Page500;