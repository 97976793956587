import ls, { USER_NOR_GET_SHI_SHI } from "../../utils/ls";
import { LOGGING_IN, LOGGING_IN_FAILED, LOGGING_IN_SUCCESS } from "./types";

export const initialState = {
  isAuthenticated: !!JSON.parse(ls.get(USER_NOR_GET_SHI_SHI)),
  loggingIn: false,
  user: JSON.parse(ls.get(USER_NOR_GET_SHI_SHI)) || {},
};

export const authReducer = (state = initialState, action) => {
  switch (action?.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGGING_IN_FAILED:
      return {
        ...state,
        loggingIn: false,
        isAuthenticated: false,
      };
    case LOGGING_IN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action?.user,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};
