import {
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
} from "./types";

import { includes } from "lodash";

export const initialState = {
  fetchingUser: false,
  editingUser: false,
  addingUser: false,
  users: {
    data: [],
  },
  deletingUser: false,
  userDeleted: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_USER:
      return {
        ...state,
        addingUser: true,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        addingUser: false,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addingUser: false,
        users: {
          ...state?.users,
          data: [action?.users, ...state?.users?.data],
        },
      };
    case DELETE_USER:
      return {
        ...state,
        deletingUser: true,
        userDeleted: [...state?.userDeleted, action?.id],
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        deletingUser: false,
        userDeleted: [],
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deletingUser: false,
        userDeleted: [],
        users: {
          ...state?.users,
          data: state?.users?.data?.filter(
            (user) => !includes(action?.id, user?.id)
          ),
          total: Number(state?.users?.total) - 1,
        },
      };

    case FETCH_USER:
      return {
        ...state,
        fetchingUser: true,
      };
    case FETCH_USER_FAILED:
      return {
        ...state,
        fetchingUser: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchingUser: false,
        users: action?.users,
      };

    default:
      return state;
  }
};
