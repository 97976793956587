import InsightList from "./InsightList";
import { useEffect, useContext} from 'react';
import { BlogContext } from "../../Context/Blog";
import { fetchBlogAction } from "../../Context/Blog/actions";

const LatestInsight = () => {

  const { blogState, dispatch} = useContext(BlogContext);
  const { blogs } = blogState;

  useEffect(() => {
    dispatch(fetchBlogAction({
      count: 3,
      status: 'published',
      orderBy: '-created_at'
    }))
  }, [dispatch])
  return (
    <div className="bg-silvergradient md:bg-silvergradient2 pt-8 pb-12 md:pt-12 lg:py-20 ">
      <div className="  px-6 md:px-12 text-chaos font-sans lg:w-78% lg:mx-auto xl:w-1200 lg:px-0 xl:px-12">
        <h3 className="text-2 text-center leading-12 font-medium">
          Latest Insights
        </h3>
        <div className="mt-6 sm:mt-10 lg:mt-14">
          <InsightList data={blogs?.data} />
        </div>
      </div>
    </div>
  );
};

export default LatestInsight;
