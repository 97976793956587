export const FETCH_VIDEO = 'FETCH_VIDEO';
export const FETCH_VIDEO_FAILED = 'FETCH_VIDEO_FAILED';
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS';

export const ADD_VIDEO = 'ADD_VIDEO_CATEGORY';
export const ADD_VIDEO_FAILED = 'ADD_VIDEO_CATEGORY_FAILED';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_CATEGORY_SUCCESS';

export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_FAILED = "DELETE_VIDEO_FAILED";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
