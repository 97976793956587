import { includes } from "lodash";

import {
  FETCH_INFOGRAPHICS,
  FETCH_INFOGRAPHICS_FAILED,
  FETCH_INFOGRAPHICS_SUCCESS,

  ADD_INFOGRAPHICS,
  ADD_INFOGRAPHICS_FAILED,
  ADD_INFOGRAPHICS_SUCCESS,

  DELETE_INFOGRAPHICS,
  DELETE_INFOGRAPHICS_COMPLETE,
  DELETE_INFOGRAPHICS_FAILED,

} from "./types";

export const initialState = {
  fetchingInfographics: false,
addingInfographics: false,
infographicsToDelete: [],
deletingInfographics: false,
  infographics: {
    data: []
  },
};

export const infographicsReducer = (state = initialState, action) => {
  switch (action?.type) {

    case DELETE_INFOGRAPHICS : return {
      ...state,
      deletingInfographics: true,
      infographicsToDelete: action?.ids
    }
    case DELETE_INFOGRAPHICS_FAILED: return {
      ...state,
      deletingInfographics: false,
      infographicsToDelete: []
    }
    case DELETE_INFOGRAPHICS_COMPLETE: {
      return {
      ...state,
      deletingInfographics: false,
      infographicsToDelete: [],
      infographics: {
        ...state.infographics,
        data: state.infographics.data.filter((datum) => !includes(action?.ids, datum?.id)),
        total: Number(state.infographics.total) - 1

      }
    }
  }
    case ADD_INFOGRAPHICS: return {
      ...state,
      addingInfographics: true
    }
    case ADD_INFOGRAPHICS_FAILED: return {
      ...state,
      addingInfographics: false,
    }
    case ADD_INFOGRAPHICS_SUCCESS: return {
      ...state,
      addingInfographics: false,
      infographics: {
        data: [action?.infographic, ...state?.infographics?.data]
      }
    }
    case FETCH_INFOGRAPHICS:
      return {
        ...state,
        fetchingInfographics: true,
      };
    case FETCH_INFOGRAPHICS_FAILED:
      return {
        ...state,
        fetchingInfographics: false,
      };
    case FETCH_INFOGRAPHICS_SUCCESS:
      return {
        ...state,
        fetchingInfographics: false,
        infographics: action?.infographics,
      };
    default:
      return state;
  }
};
