import { ReactComponent as Logo } from "../../Assets/Icons/logo.svg";
import { ReactComponent as TwitterIcon } from "../../Assets/Icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../../Assets/Icons/instagram.svg";

import navList from "./footerNavList";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-chaos font-sans">
      <div className="px-6 py-10 flex flex-col items-center text-center font-sans text-white lg:items-start lg:flex-row lg:justify-between lg:w-78% lg:mx-auto lg:px-0 xl:w-1200 xl:px-12 lg:pb-4 lg:pt-9 ">
        <div className="flex flex-col items-center md:w-450 lg:w-1/3 flex-shrink-0 lg:items-start lg:text-left">
          <Logo />
          <h6 className="mt-6 md:mt-8 text-sm leading-21 lg:pr-12">
            The Nigerian oil & gas industry has historically been used to pander
            to the needs of very few highly placed in the society, however this
            is not how an ideal oil & gas industry should be run.
          </h6>
          <div className="flex mt-2 md:mt-4 lg:mt-5">
            <a
              className="mr-3.5 last:mr-0"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/BudgITng"
            >
              <TwitterIcon />
            </a>
            <a
              className="mr-3.5 last:mr-0"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/budgitng"
            >
              <FacebookIcon />
            </a>
            <a
              className="mr-3.5 last:mr-0"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/budgitng/"
            >
              <Instagram />
            </a>
          </div>
        </div>

        <div className="mt-12 md:mt-14 text-center md:flex lg:justify-between  md:w-full lg:w-2/3 lg:text-left">
          {navList?.map((navItem, index) => (
            <ul
              key={index}
              className="mb-10 last:mb-0 lg:w-1/5  md:even:mx-15 flex-shrink-0 "
            >
              {navItem.heading && (
                <li className="text-sm leading-21 mb-10px last:mb-0 cursor-pointer">
                  <h3>{navItem.heading}</h3>
                </li>
              )}
              {navItem?.nav?.map((nav, index) => (
                <li
                  key={index}
                  className="text-sm leading-21 mb-10px last:mb-0 cursor-pointer"
                >
                  <Link to={nav?.path}>{nav?.title}</Link>
                </li>
              ))}
            </ul>
          ))}
          <ul className="mb-10 last:mb-0  md:even:mx-15  lg:w-2/5 flex-shrink-0">
            <li className="text-md leading-21 mb-10px last:mb-0 cursor-pointer">
              <h3>CONTACT US</h3>
            </li>
            <li className="text-sm leading-21 mb-10px last:mb-0 cursor-pointer ">
              <h3 className="text-md font-bold leading-21 mb-10px text-fireBrick ">
                Lagos
              </h3>
              <div>
                <p className="mb-2">
                  BudgIT 55 Moleye Street, Alagomeji, Lagos
                </p>
                <p className=" text-faded">
                  +2349083331633 | +2348037276668 | +2347034944492
                </p>
              </div>
              <h3 className="text-md font-bold leading-21 mb-10px text-fireBrick mt-2">
                Abuja
              </h3>
              <div>
                <p className="mb-2">
                  Box Office Hub, 1473 Albert Osakwe House, close to Nigerian
                  Agricultural Insurance Cooperation (NAIC), Church Gate Axis,
                  Central Business District, Abuja
                </p>
                <p className=" text-faded">
                  +2349083331633 | +2348037276668 | +2347034944492
                </p>
              </div>
            </li>
            <li className="my-6 block">
              <a
                className="bg-fig px-6 py-4 rounded-md"
                target="_blank"
                rel="noreferrer"
                href="https://dashboard.flutterwave.com/pay/budgit"
              >
                Donate
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="px-6 text-white font-sans md:px-0">
        <div className="p-2 md:p-3 text-sm leading-21 flex items-center justify-center border-t border-white">
          <span className="mr-2">
            &copy;{new Date().getFullYear()} - BudgIT
          </span>
          <span className="mr-2">|</span>
          <span>All right reserved</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
