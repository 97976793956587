import {
  ADD_DATA_CATEGORY,
  ADD_DATA_CATEGORY_FAILED,
  ADD_DATA_CATEGORY_SUCCESS,
  ADD_DATASET,
  ADD_DATASET_FAILED,
  ADD_DATASET_SUCCESS,

  UPDATING_DATA_CATEGORY,
  UPDATING_DATA_CATEGORY_COMPLETE,

  ADD_DATA_SUB_CATEGORY,
  ADD_DATA_SUB_CATEGORY_COMPLETE,
} from "./types";

export const initialState = {
  addingDataCategory: false,
  addingDataset: false,
  updatingCategory: false,
  addingSubCategory: false,

};

export const dataReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_DATA_CATEGORY:
      return {
        ...state,
        addingDataCategory: true,
      };
    case ADD_DATA_CATEGORY_FAILED:
      return {
        ...state,
        addingDataCategory: false,
      };
    case ADD_DATA_CATEGORY_SUCCESS:
      return {
        ...state,
        addingDataCategory: false,
      };
    case ADD_DATASET:
      return {
        ...state,
        addingDataset: true,
      };
    case ADD_DATASET_FAILED:
      return {
        ...state,
        addingDataset: false,
      };
    case ADD_DATASET_SUCCESS:
      return {
        ...state,
        addingDataset: false,
      };
    case UPDATING_DATA_CATEGORY: return {
      ...state,
      updatingCategory: true,
    }
    case UPDATING_DATA_CATEGORY_COMPLETE: return {
      ...state,
      updatingCategory: false,
    }
    case ADD_DATA_SUB_CATEGORY: return {
      ...state,
      addingSubCategory: true,
    }
    case ADD_DATA_SUB_CATEGORY_COMPLETE: return {
      ...state,
      addingSubCategory: false,
    }
    default:
      return state;
  }
};
