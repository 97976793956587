import { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ReactComponent as MenuIcon } from "../../Assets/Icons/menu.svg";
import { ReactComponent as Logo } from "../../Assets/Icons/logo.svg";
// import { ReactComponent as SearchIcon } from "../../Assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close.svg";
import navList from "./navList";
import DesktopDropdownList from "./DropdownList/DesktopList";
import Sidebar from "./Sidebar";
import "./nav.css";
import clsx from "clsx";
import routes from "../../utils/routes";
import { debounce } from "lodash";

const Navigation = () => {
  const {
    location: { pathname },
  } = useHistory();
  const dropdownRef = useRef(null);
  const sideBarTogglerRef = useRef(null);
  const navRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [makeTransparent, setMakeTransparent] = useState(true);
  const [activeDropdownData, setActiveDropdownData] = useState({});
  const [activeBanner, setActiveBanner] = useState("");

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < 0) {
        setMakeTransparent(false);
      }
      if (currPos.y === 0) {
        setMakeTransparent(true);
      }
    },
    [makeTransparent],
    false,
    false,
    300
  );

  const toggleSidebar = (e) => {
    e.preventDefault();
    if (sideBarTogglerRef.current) {
      sideBarTogglerRef.current.checked = !sideBarTogglerRef.current.checked;
      if (isSidebarOpen) {
        setTimeout(() => {
          setIsSidebarOpen(false);
        }, 350);
        return;
      }
      setIsSidebarOpen(true);
    }
  };

  const openDropdown = (dropdownData) => {
    if (dropdownRef.current) {
      dropdownRef.current.checked = true;
      setIsDropdownOpen(true);
      setActiveDropdownData(dropdownData);
      setActiveBanner(dropdownData?.banner);
    }
  };

  const debouncedOpenDropdown = useRef(
    debounce(
      (dropdownData) => {
        if (dropdownRef.current) {
          dropdownRef.current.checked = true;
          setIsDropdownOpen(true);
          setActiveDropdownData(dropdownData);
          setActiveBanner(dropdownData?.banner);
        }
      },
      1000,
      {
        trailing: true,
      }
    )
  );

  const cancelOpenDropdown = () => debouncedOpenDropdown.current.cancel();

  const closeDropdown = (e) => {
    if (dropdownRef.current) {
      dropdownRef.current.checked = false;
      setTimeout(() => {
        setIsDropdownOpen(false);
      }, 1000);
    }
  };

  return (
    <>
      <div
        className={clsx({
          "h-50  top-0 w-full bg-white lg:hidden": true,
          hidden: !isSidebarOpen,
        })}
      ></div>
      <nav
        ref={navRef}
        className={clsx({
          "bg-white  md:w-full hover:bg-white transition z-50 md:fixed": true,
          "fixed w-full top-0": isSidebarOpen,
          "lg:bg-transparent":
            !isDropdownOpen && pathname === routes.home && makeTransparent,
          "lg:bg-white": isDropdownOpen,
        })}
      >
        {/* MAINTENANCE BLOCK */}
        <div className="bg-fig text-white py-4">
          <div className="px-6 md:px-12 lg:w-78% lg:mx-auto xl:w-1200 lg:px-0 xl:px-12 text-sm">
            We are currently undergoing some maintenance at the moment. For more
            information, reach out to{" "}
            <a href="mailto:deborah@budgit.org">deborah@budgit.org</a>
          </div>
        </div>

        <div className="  h-50 md:h-58 flex items-center px-6 justify-between  md:px-12 lg:h-74	lg:w-78%  lg:mx-auto xl:w-1200 lg:px-0 xl:px-12  ">
          <button
            onClick={toggleSidebar}
            className="md:hidden outline-none cursor-pointer"
          >
            {!isSidebarOpen && <MenuIcon />}
            {isSidebarOpen && <CloseIcon />}
          </button>
          <button type="button" onClick={closeDropdown} className="logo">
            <Link to={routes.home}>
              <Logo />
            </Link>
          </button>
          <ul className=" items-center hidden md:flex font-sans text-xs leading-18 text-chaos lg:text-sm lg:leading-5">
            {navList.map((nav) => {
              if (!nav?.dropdown) {
                return (
                  <li
                    onClick={closeDropdown}
                    key={nav?.title}
                    className={clsx({
                      "mr-19 lg:mr-14 last:mr-0 hover:text-fig cursor-pointer": true,
                      "text-fig": pathname?.includes(nav?.title?.toLowerCase()),
                    })}
                  >
                    <Link to={nav?.path}>{nav?.title}</Link>
                  </li>
                );
              }
              return (
                <li
                  key={nav?.title}
                  onMouseLeave={cancelOpenDropdown}
                  onMouseEnter={() =>
                    debouncedOpenDropdown.current(nav?.dropdownData)
                  }
                  onClick={() => openDropdown(nav?.dropdownData)}
                  className={clsx({
                    "mr-19 lg:mr-14 last:mr-0 hover:text-fig cursor-pointer": true,
                    "text-fig": pathname?.includes(nav?.title?.toLowerCase()),
                  })}
                >
                  {nav?.title}
                </li>
              );
            })}
          </ul>

          <div></div>
        </div>
      </nav>

      <DesktopDropdownList
        activeBanner={activeBanner}
        setActiveBanner={setActiveBanner}
        dropdownData={activeDropdownData}
        closeDropdown={closeDropdown}
        dropdownRef={dropdownRef}
      />
      <Sidebar
        navList={navList}
        toggleSidebar={toggleSidebar}
        sideBarTogglerRef={sideBarTogglerRef}
      />
    </>
  );
};

export default Navigation;
