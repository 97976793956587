import { includes } from "lodash";
import {
  ADD_BLOG,
  ADD_BLOG_FAILED,
  ADD_BLOG_SUCCESS,
  FETCH_BLOG,
  FETCH_BLOG_FAILED,
  FETCH_BLOG_SUCCESS,

  PUBLISH_BLOG,
  PUBLISH_BLOG_COMPLETE,
  PUBLISH_BLOG_FAILED,

  DELETE_BLOG,
  DELETE_BLOG_FAILED,
  DELETE_BLOG_SUCCESS,


} from "./types";

export const initialState = {
  fetchingBlogs: false,
  addingBlog: false,
  blogsToPublish: [],
  blogsToDelete: [],
  blogs: {
    data: []
  },
};

export const blogReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_BLOG: return {
      ...state,
      addingBlog: true,
    }
    case ADD_BLOG_FAILED: return {
      ...state,
      addingBlog: false
    }
    case ADD_BLOG_SUCCESS: return {
      ...state,
      addingBlog: false,
      blogs: {
        ...state?.blogs,
        data: [action?.blog, ...state?.blogs?.data]
      }
    }
    case DELETE_BLOG:
      return {
        ...state,
        blogsToDelete: [...state?.blogsToDelete, ...action?.ids],
      };
    case DELETE_BLOG_FAILED:
      return {
        ...state,
        blogsToDelete: state?.blogsToDelete?.filter(
          (id) => !includes(action?.ids, id)
        ),
      };
    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blogsToDelete: state?.blogsToDelete?.filter(
          (id) => !includes(action?.ids, id)
        ),
        blogs: {
          ...state?.blogs,
          data: state?.blogs?.data?.filter((blog) => {
            return !includes(action?.ids, blog?.id);
          }),
        },
      };
    case PUBLISH_BLOG:
      return {
        ...state,
        blogsToPublish: [...state?.blogsToPublish, action?.id],
      };
    case PUBLISH_BLOG_FAILED:
      return {
        ...state,
        blogsToPublish: state?.blogsToPublish?.filter(
          (blogId) => blogId !== action?.id
        ),
      };
    case PUBLISH_BLOG_COMPLETE:
      return {
        ...state,
        blogsToPublish: state?.blogsToPublish?.filter(
          (blogId) => blogId !== action?.id
        ),
        blogs: {
          ...state?.blogs,
          data: state?.blogs?.data?.map((blog) => {
            if (blog?.id !== action?.id) {
              return blog;
            }
            return {
              ...blog,
              published: action?.published,
            };
          }),
        },
      };
    case FETCH_BLOG:
      return {
        ...state,
        fetchingBlogs: true,
      };
    case FETCH_BLOG_FAILED:
      return {
        ...state,
        fetchingBlogs: false,
      };
    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        fetchingBlogs: false,
        blogs: action?.blogs,
      };
    default:
      return state;
  }
};
