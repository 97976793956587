import { CREATE_PIA, CREATE_PIA_FAILED, CREATE_PIA_SUCCESS } from "./types";

export const initialState = {
  addingPia: false,
  pia: {
    data: [],
  },
};

export const videoReducer = (state = initialState, action) => {
  switch (action?.type) {
    case CREATE_PIA:
      return {
        ...state,
        addingPia: true,
      };
    case CREATE_PIA_FAILED:
      return {
        ...state,
        addingPia: false,
      };
    case CREATE_PIA_SUCCESS:
      return {
        ...state?.pia,
        data: [action?.pia, ...state?.pia?.data],
      };
    default:
      return state;
  }
};
