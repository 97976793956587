import React from "react";
import { videoReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const PiaContext = React.createContext();

const LegalContextWrapper = ({ children }) => {
  const [piaState, dispatch] = useThunkReducer(videoReducer, initialState);

  const value = {
    piaState,
    dispatch,
  };
  return (
    <PiaContext.Provider value={value}>{children}</PiaContext.Provider>
  );
};

export default LegalContextWrapper;
