
const HomeAbout = () => {
    return ( <div className="">
        <div className="text-chaos px-6 py-8 md:py-12 md:px-12 lg:w-78% lg:mx-auto lg:py-88 lg:px-0 xl:px-12 xl:w-1200 ">
            <h6 className=" font-medium text-2 text-center leading-12">
            Who We Are
            </h6>
            <div className="mt-5 md:mt-10 lg:flex lg:items-center lg:mt-14">

            <div className="h-174 md:h-80 lg:h-259 lg:w-2col flex-shrink-0 lg:mr-30 ">
               <img src="https://res.cloudinary.com/budgit/image/upload/q_10/v1619452903/FIXOUROIL/Blog_Banner_adxggr.png" className="w-full h-full object-cover" alt="about us"  />
            </div>
            <p className="text-justify mt-4 font-medium text-sm leading-7 text-ebony font-sans md:mt-8 md:text-base lg:w-2col lg:mt-0">
            Our work revolves around four key pillars - pushing the advocacy for beneficial ownership reforms, pushing for reforms in Nigeria’s state-owned enterprise, advocating for constant review of legislatures governing the oil and gas sector, and promoting accountability in revenue generation in the extractive sector.
            </p>
            </div>
        </div>
    </div> );
}
 
export default HomeAbout;