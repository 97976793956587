import { includes } from "lodash";

import {
  FETCH_POLICY,
  FETCH_POLICY_FAILED,
  FETCH_POLICY_SUCCESS,

  ADD_POLICY,
  ADD_POLICY_FAILED,
  ADD_POLICY_SUCCESS,

  DELETE_POLICY,
  DELETE_POLICY_FAILED,
  DELETE_POLICY_SUCCESS,

} from "./types";

export const initialState = {
  fetchingPolicy: false,
  addingPolicy: false,
  policyToDelete: [],
  deletingPolicy: false,
  policy: {
    data: []
  },
};

export const policyReducer = (state = initialState, action) => {
  
  switch (action?.type) {

    case DELETE_POLICY : return {
      ...state,
      deletingPolicy: true,
      policyToDelete: action?.ids
    }
    case DELETE_POLICY_FAILED: return {
      ...state,
      deletingPolicy: false,
      policyToDelete: []
    }
    case DELETE_POLICY_SUCCESS: {
      return {
      ...state,
      deletingPolicy: false,
      policyToDelete: [],
      policy: {
        ...state.policy,
        data: state.policy.data.filter((datum) => !includes(action?.ids, datum?.id)),
        total: Number(state.policy.total) - 1

      }
    }
  }

    case ADD_POLICY_SUCCESS: return {
      ...state,
      addingPolicy: false,
      policy: {
        ...state?.policy,
        data: [action?.policy, ...state?.policy?.data]
      }
    }
    case ADD_POLICY_FAILED: return {
      ...state,
      addingPolicy: false,
    }
    case ADD_POLICY: return {
      ...state,
      addingPolicy: true
    }
    case FETCH_POLICY:
      return {
        ...state,
        fetchingPolicy: true,
      };
    case FETCH_POLICY_FAILED:
      return {
        ...state,
        fetchingPolicy: false,
      };
    case FETCH_POLICY_SUCCESS:
      return {
        ...state,
        fetchingPolicy: false,
        policy: action?.policy,
      };
    default:
      return state;
  }
};
