import FocusList from "./FocusList";

const FocusArea = () => {
  return (
    <div className="bg-white font-sans">
      <div className="px-6 py-8 text-chaos lg:w-78% md:px-12 lg:mx-auto lg:px-0 xl:w-1200 xl:px-12 md:py-12 lg:py-88">
        <h3 className="text-2 text-center leading-12 font-medium text-greenBrown">
          Our Focus Area
        </h3>
        <div className="mt-6 md:mt-10 lg:mt-14">
          <FocusList />
        </div>
      </div>
    </div>
  );
};

export default FocusArea;
