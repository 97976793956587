import Loader from './Lottie';
import loader from '../Assets/json/loader.json';
const LoaderWrapper = () => {
    return (         <span className="pt-10 px-6 md:px-12 lg:px-0 xl:px-12 flex justify-center">
    <span className="">
         <Loader loader={loader} />
       </span>
   </span> );
}
 
export default LoaderWrapper;