import React from 'react';
import { legalReducer, initialState } from './reducer';
import useThunkReducer from '../../HOC/useThunkReducer';

export const LegalContext = React.createContext();

const LegalContextWrapper = ({ children }) => {

    const [legalState, dispatch] = useThunkReducer(legalReducer, initialState);

    const value = {
        legalState,
        dispatch,
    }
    return (<LegalContext.Provider value={value}>
        {children}
    </LegalContext.Provider>)
}

export default LegalContextWrapper;

