export const FETCH_PUBLICATION = 'FETCH_PUBLICATION';
export const FETCH_PUBLICATION_SUCCESS = 'FETCH_PUBLICATION_SUCCESS';
export const FETCH_PUBLICATION_FAILED = 'FETCH_PUBLICATION_FAILED';

export const ADD_PUBLICATION = 'ADD_PUBLICATION';
export const ADD_PUBLICATION_FAILED = 'ADD_PUBLICATION_FAILED';
export const ADD_PUBLICATION_SUCCESS = 'ADD_PUBLICATION_SUCCESS';

export const DELETE_PUBLICATION = 'DELETE_PUBLICATION';
export const DELETE_PUBLICATION_FAILED = 'DELETE_PUBLICATION_FAILED';
export const DELETE_PUBLICATION_SUCCESS = 'DELETE_PUBLICATION_SUCCESS';
