import { gql } from "@apollo/client";

export const GET_DATA_CATEGORIES = gql`
  query getDataCategories {
    FixOurOilDataCategories(order_by: { createdAt: desc }) {
      id
      name
    }
  }
`;

export const GET_SINGLE_CATEGORY_SUMMARY_FOR_A_YEAR = gql`
query getSingleCategorySummaryForByYear($year: String!, $categoryId: Int!) {
  Years(where: {year: {_eq: $year}}) {
    id
    year
    FixOurOils(where: {Year: {year: {_eq: $year}}}) {
      id
      name
      FixOurOilDataSets(where: {fixOurOilDataCategoryId: {_eq: $categoryId}}) {
        id
        FixOurOilDataValues_aggregate {
          aggregate {
            sum {
              value
            }
          }
        }
      }
    }
  }
  FixOurOilDataCategories(where: {id: {_eq: $categoryId}}) {
    id
    name
    trending
    metrics
    sourceFileUrl
    frequency
    description
    createdAt
    updatedAt
  }
}

`

export const GET_SINGLE_DATA_CATEGORY = gql`
  query getSingleDataCategory($id: Int!) {
    FixOurOilDataCategories(where: { id: { _eq: $id } }) {
      createdAt
      id
      name
      updatedAt
      description
      frequency
      metrics
      trending
    }
  }
`;

// export const GET_SINGLE_CATEGORY_SINGLE_SUB_CATEGORY

export const GET_DATA_CATEGORIES_FOR_ADMIN = gql`
  query getDataCategoriesAdmin {
    FixOurOilDataCategories(order_by: { createdAt: desc }) {
      createdAt
      id
      name
      updatedAt
      description
      frequency
      metrics
      trending
      sourceFileUrl
    }
    FixOurOilDataTypes {
      createdAt
      description
      id
      name
    }
  }
`;

export const GET_ACTIVE_COUNTRY = gql`
  query getActiveCountry {
    Countries(where: { name: { _like: "Nigeria" } }) {
      id
      name
    }
  }
`;

export const GET_SUB_DATA_CATEGORY = gql`
  query getSubDataCategory {
    FixOurOilDataTypes(
      order_by: { createdAt: desc }
    ) {
      id
      name
      description
    }
  }
`;


export const GET_SUB_DATA_CATEGORY_FOR_CATEGORY = gql`
  query getSubDataCategoryForACategory($categoryId: Int!) {
    FixOurOilDataTypes(
      where: {
        FixOurOilDataSets: {
          FixOurOilDataCategory: { id: { _eq: $categoryId } }
        }
      }
      order_by: { createdAt: desc }
    ) {
      id
      name
      description
    }
  }
`;

export const GET_LIST_OF_YEARS = gql`
query getListOfYears {
  Years(order_by: {year: asc}) {
    id
    year
  }
}
`

export const GET_TRENDING_CATEGORY_DATA = gql`
  query getTrendingCategoryData {
    FixOurOilDataCategories(where: { trending: { _eq: "true" } }) {
      trending
      name
      id
      metrics
      FixOurOilDataSets {
        FixOurOilDataValues_aggregate {
          aggregate {
            sum {
              value
            }
          }
        }
      }
    }
  }
`;