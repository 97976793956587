import React from "react";
import { videoReducer, initialState } from "./reducer";
import useThunkReducer from "../../HOC/useThunkReducer";

export const VideoContext = React.createContext();

const LegalContextWrapper = ({ children }) => {
  const [videoState, dispatch] = useThunkReducer(videoReducer, initialState);

  const value = {
    videoState,
    dispatch,
  };
  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export default LegalContextWrapper;
