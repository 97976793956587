import {
  CREATE_COMMITMENT,
  CREATE_COMMITMENT_FAILED,
  CREATE_COMMITMENT_SUCCESS,
  CREATE_COMMITMENT_ACTIVITY,
  CREATE_COMMITMENT_ACTIVITY_COMPLETE,
} from "./types";

export const initialState = {
  addingCommitments: false,
  addingCommitmentsActivity: false,
};

export const commitmentReducer = (state = initialState, action) => {
  switch (action?.type) {
    case CREATE_COMMITMENT:
      return {
        ...state,
        addingCommitments: true,
      };
    case CREATE_COMMITMENT_ACTIVITY:
      return {
        ...state,
        addingCommitmentsActivity: true,
      };
    case CREATE_COMMITMENT_ACTIVITY_COMPLETE:
      return {
        ...state,
        addingCommitmentsActivity: false,
      };
    case CREATE_COMMITMENT_FAILED:
    case CREATE_COMMITMENT_SUCCESS:
      return {
        ...state,
        addingCommitments: false,
      };

    default:
      return state;
  }
};
