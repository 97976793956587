import { useMediaQuery } from "react-responsive";
import { useRef } from "react";

const FocusCard = ({ focus }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const FocusEl = useRef(null);
  const ImageEl = useRef(null);

  const handleScaling = (e) => {
    e.preventDefault();
    FocusEl.current.dataset.scaled = "true";
    ImageEl.current.src = focus.clearUrl;
  };
  const handleDescaling = (e) => {
    e.preventDefault();
    FocusEl.current.dataset.scaled = "false";
    ImageEl.current.src = focus.darkUrl;
  };
  return (
    <div
      ref={FocusEl}
      data-scaled="false"
      className="h-288 lg:h-488 mb-5 last:mb-0 first:rounded-l overflow-hidden last:rounded-r relative flex-shrink-0 lg:w-1/4 lg:mr-px lg:last:mr-0 cursor-pointer focus"
    >
      <img
        ref={ImageEl}
        src={isMobile ? focus?.clearUrl : focus?.darkUrl}
        alt="focus area"
        className="w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 bottom-0 right-0 bg-darkgradient flex items-end font-sans topContent ">
        <div className="flex text-center flex-col items-center p-5 text-white w-full ">
          <h6 className="text-xl leading-30 font-bold tracking-wide md:text-2xl md:leading-9 lg:text-base lg:leading-6">
            {focus?.title}
          </h6>
          <p className="text-xs font-semibold leading-21 md:text-sm lg:hidden">
            {focus?.description}
          </p>
        </div>
      </div>
      <div
        onMouseEnter={handleScaling}
        onMouseLeave={handleDescaling}
        className="hidden lg:flex overlay  items-center justify-center"
      >
        <div className="overlay__content text-white flex items-center justify-center flex-col px-8">
          <h6 className="text-3xl leading-45 font-bold tracking-wide  text-center mb-2 ">
            {focus?.title}
          </h6>
          <p className="text-xs font-semibold leading-21 text-center ">
            {focus?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FocusCard;
