import Avatar from "react-avatar";
import dateformat from "dateformat"
import parser from "html-react-parser";
import routes from "../../utils/routes";
import { Link } from 'react-router-dom';

const InsightCard = ({ blog }) => {
  console.log(blog)
  return (
    <div className="font-sans mb-5 last:mb-0 sm:w-2col sm:even:ml-30 md:w-3col md:even:mx-30 ">
      <Link to={`${routes?.singleBlog}/${blog?.id}`}>
      
      <div className=" h-174 w-full">
        <Avatar
          size="100%"
          name="fab"
          src={blog?.thumbnail || blog?.title}
          className="bg-fig rounded"
        ></Avatar>
      </div>
      <div className="pr-2 md:pr-4 h-204 overflow-hidden">

      <span className="text-10px leading-4 text-slate  mt-2 font-sans">
        {!!blog?.created_at && `${dateformat(new Date(blog?.created_at.replace(/-/g, "/")), 'dd mmm, yyyy')} by ${blog?.user?.name}`}
      </span>
      <h6 className="font-bold leading-7 text-sm mt-2 lg:text-base lg:leading-relaxed	">
        {blog?.title}
      </h6>
      <p className="text-xs text-slate leading-5 mt-2 font-normal lg:text-sm lg:leading-snug">
        {parser(blog?.description)}
      </p>
      </div>
      </Link>
    </div>
  );
};

export default InsightCard;
