import "./ogp.css";
import Commitment from "./Commitments";
import Loader from "../Commons/Loader";
import { useQuery } from "@apollo/client";
import { GET_COMMITMENT_AND_ACTIVITIES } from "../Apollo/Queries/commitments";
import Pagination from "../Commons/Pagination/Main";
import NoData from "../Commons/NoData";
import { useState, useRef, useEffect } from "react";

const limit = 10;

const Ogp = () => {
  const [offset, setOffset] = useState();
  const isLoaded = useRef(false);

  const {
    loading,
    data: {
      FixOurOilCommitments = [],
      FixOurOilCommitments_aggregate: { aggregate: { count } = {} } = {},
    } = {},
    refetch,
  } = useQuery(GET_COMMITMENT_AND_ACTIVITIES, {
    variables: {
      limit,
      offset: offset || 0,
    },
  });


  const paginationHandler = ({ selected }) => {
    setOffset(limit * selected)
  };

  useEffect(() => {
    if (isLoaded.current) {
      refetch({
        variables: {
            offset
        },
      });
    } else {
      isLoaded.current = true;
    }
  }, [offset, refetch]);

  return (
    <div className="md:pt-12 lg:pt-20">
      <div className="progresstracker w-full rounded-sm h-184 lg:h-300">
        <h1 className="text-2xl text-white font-sans leading-9 font-medium md:text-2 md:leading-12 lg:text-5xl lg:leading-72">
          Progress Tracker-O.G.P
        </h1>
      </div>
      {loading && <Loader />}

      {!loading && !!FixOurOilCommitments.length && (
        <>
          {FixOurOilCommitments?.map((commitment) => (
            <div key={commitment.id} className="pb-10 md:pb-12 lg:pb-20">
              <Commitment commitment={commitment} />
            </div>
          ))}
{  (count / limit) > 1 &&        <div className="w-full flex items-center justify-center py-10">
            <Pagination
              currentPage={!!offset ? Number(offset) / limit : 0}
              onPageChange={paginationHandler}
              to={Number(count) / limit}
            />
          </div>}
        </>
      )}
      {!loading && !FixOurOilCommitments.length && (
        <div className="pt-10 px-6 md:px-12 lg:px-0 xl:px-12">
          <NoData />
        </div>
      )}
    </div>
  );
};

export default Ogp;
