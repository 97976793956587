import { useEffect, useContext } from "react";
import { InfographicContext } from "../../Context/Infographics";
import { fetchInfographicsAction } from "../../Context/Infographics/actions";
import InfographicsCard from "../../Commons/InfographicCard";
import Button from "../../Commons/Button/PrimaryButton";
import { Link } from "react-router-dom";
import routes from "../../utils/routes";

const Infographics = () => {
  const { infographicState, dispatch } = useContext(InfographicContext);
  const {
    infographics: { data },
  } = infographicState;

  useEffect(() => {
    dispatch(
      fetchInfographicsAction({
        count: 6,
        page: 1,
        type: "infographic",
        orderBy: "-created_at",
      })
    );
  }, [dispatch]);

  return (
    <div className="bg-white font-sans">
      <div className="px-6 py-8 text-chaos lg:w-78% md:px-12 lg:mx-auto lg:px-0 xl:w-1200 xl:px-12 md:py-12 lg:py-88">
        <h3 className="text-2 text-center leading-12 font-medium text-fig">
          Infographics
        </h3>
        <div className="mt-6 md:mt-10 lg:mt-14 flex flex-wrap">
          {!!data?.length &&
            data?.map((info, index) => {
              return (
                <div
                  className=" min-h-109.5 mb-6  block resourceItem"
                  key={`${index}-1`}
                >
                  <InfographicsCard key={index} infographic={info} />
                </div>
              );
            })}
        </div>
        <div className="flex items-center justify-center mt-12">
          <div>
            <Link to={routes.infographics}>
              <Button variant="secondary">
                <span className="inline-block px-8 py-3 ">
                  <span>See More</span>
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infographics;
