import Activities from './activity';

const Commitment= ({
    commitment
}) => {
    return(
        <div className="px-6 md:px-12 lg:px-0 lg:w-78% lg:mx-auto xl:w-1200 xl:px-12">
            <div className="font-sans pt-10 md:pt-12 lg:pt-20">
                <h2 className="font-medium text-2 leading-12 text-fig">{commitment?.name}</h2>
                <p className="text-base leading-8">{commitment.description}</p>
            </div>
           { !!commitment?.FixOurOilCommitmentItems.length && commitment?.FixOurOilCommitmentItems.map((activity) => <Activities activity={activity} />) }
        </div>
    )
}

export default Commitment;