import React from 'react';
import Pagination from '.';
import { ReactComponent as ChevronLeftIcon } from '../../Assets/Icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../Assets/Icons/chevron-right.svg';
// import { ReactComponent as DotsIcon } from '../../Assets/Icons/dots.svg';
import './pagination.css';

const MainPagination = ({ to = 10, currentPage = 1, onPageChange = () => {} }) => {
  return (
    <Pagination
      nextLabel={<ChevronLeftIcon />}
      previousLabel={<ChevronRightIcon />}
      pageCount={to}
      onPageChange={onPageChange}
      // breakLabel={<DotsIcon />}
      containerClassName={"flex items-center w-full"}
      pageClassName={
        "w-9 flex items-center justify-center h-9 rounded text-10px font-poppins cursor-pointer font-semibold text-chocolateKiss lg:text-sm lg:mr-2"
      }
      activeClassName={"bg-fig text-white flex-shrink-0 rounded-md"}
      activeLinkClassName={
        "text-white outline-none focus:outline-none active:outline-none font-bold"
      }
      pageLinkClassName={
        " w-full h-full flex items-center justify-center outline-none focus:outline-none active:outline-none"
      }
      breakClassName={
        "outline-none focus:outline-none active:outline-none"
      }
      breakLinkClassName={"outline-none focus:outline-none active:outline-none"}
      nextPrevClassName={
        "text-chocolateKiss outline-none focus:outline-none active:outline-none"
      }
      disabledNextPrevClassName={"text-transparent bg-transparent hidden"}
      forcePage={currentPage}
      initialPage={currentPage}
    />
  );
};

export const RowsSelection = ({ options = [{ value: 100 }, { value: 200 }, { value: 1000 }], selectedValue = "", onChange = () => {} }) => {
  return (
    <div className="flex items-center">
      <h6 className="text-fig font-poppins text-10px tracking-open font-medium mr-1">
        Rows per page
      </h6>
      <div className="w-50 h-29 flex items-center relative cursor-pointer">
        <select
          value={selectedValue}
          onChange={onChange}
          className="appearance-none outline-none focus:outline-none text-10px cursor-pointer text-fig pl-2 font-poppins font-medium justify-center w-full h-full rounded rowSelect py-3 px-4"
        >
          {options.map(({ value }) => (
            <option value={value}>{value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MainPagination;