import LRU from "lru-cache";


import { values } from 'lodash';

export const getCacheKey = (params) => {
 return `${values(
    params
  ).join("/")}`
}


const options = { max: 500, maxAge: 1000 * 60 * 60 };

const cache = new LRU(options);

export default cache;

