export const DELETE_PIA = 'DELETE_PIA';
export const DELETE_PIA_FAILED = 'DELETE_PIA_FAILED';
export const DELETE_PIA_SUCCESS = 'DELETE_PIA_SUCCESS';


export const CREATE_PIA = 'CREATE_PIA';
export const CREATE_PIA_FAILED = 'CREATE_PIA_FAILED';
export const CREATE_PIA_SUCCESS = 'CREATE_PIA_SUCCESS';

export const UPDATE_PIA = 'UPDATE_PIA';
export const UPDATE_PIA_FAILED = 'UPDATE_PTA_FAILED';
export const UPDATE_PIA_SUCCESS = 'UPDATE_PIA_SUCCESS';

