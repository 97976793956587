import createApiService from '../../http';

const FETCH_DOCUMENT = 'legalDocuments';
const UPLOAD_DOCUMENT = 'legalDocuments/upload';
const DELETE_DOCUMENT = 'legalDocuments'

export const fetchDocumentService = (params) =>
  createApiService({
    method: "get",
    endpoint: FETCH_DOCUMENT,
    options: {
      params: {
        ...params,
      },
    },
  });

export const createDocumentService = (token) =>
  createApiService({
    method: "post",
    endpoint: UPLOAD_DOCUMENT,
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
  });

export const deleteDocumentService = (token, params) => createApiService({
  method: 'delete',
  endpoint: DELETE_DOCUMENT,
  options: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...params
    }
  },
})
