import ReactPlayer from "react-player";
import closeIcon from "../../Assets/Icons/close2.svg";

const FullScreenPlayer = ({
  onCancel,
  video,
  controls = true,
  isPlaying = true,
}) => {
  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div
      onClick={cancel}
      className="relative bg-black bg-opacity-80 w-screen h-screen flex items-center justify-center"
    >
      <button className="absolute top-0 right-0 p-8 text-white">
        <img
          className="object-cover"
          src={closeIcon}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <div className="layout__wrapper h-1/2 w-full">
        <ReactPlayer
          controls={controls}
          playing={isPlaying}
          width="100%"
          height="100%"
          onEnded={cancel}
          url={video?.file_url
          }
        />
      </div>
    </div>
  );
};

export default FullScreenPlayer;
