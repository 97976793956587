import createApiService from "../../http";

const FETCH_BLOG = "blogs";
const CREATE_BLOG = "blog/upload";
const PUBLISH_BLOG = "blogs/publish";
const DELETE_BLOG = 'blogs';
const UPDATE_BLOG = 'blogs';
const FETCH_SINGLE_BLOG = 'blogs/view';




export const fetchSingleBlog = (id) =>
  createApiService({
    method: "get",
    endpoint: `${FETCH_SINGLE_BLOG}/${id}`,
   
  });

  
export const fetchBlogService = (params) =>
  createApiService({
    method: "get",
    endpoint: FETCH_BLOG,
    options: {
      params: {
        ...params,
      },
    },
  });

export const addBlogService = (token) =>
  createApiService({
    method: "post",
    endpoint: CREATE_BLOG,
    options: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

export const publishBlogService = (token) =>
  createApiService({
    method: "post",
    endpoint: PUBLISH_BLOG,
    options: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

export const updateBlogService = (token, id) =>
  createApiService({
    method: "put",
    endpoint: `${UPDATE_BLOG}/${id}`,
    options: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  export const deleteBlogService = (token, params) => createApiService({
    method: 'delete',
    endpoint: DELETE_BLOG,
    options: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...params
      }
    },
  })
