import routes from "../../utils/routes";

const navList = [
  {
    title: "ABOUT",
    dropdown: true,
    dropdownData: {
      banner:
        "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464028/FIXOUROIL/Nav_-_About_aeru4a.png",
      nav: [
        {
          title: "ABOUT US",
          path: routes.about,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464052/FIXOUROIL/Nav_-_About_About-us_miesaw.png",
        },
        // {
        //   title: "LEGAL FRAMEWORK",
        //   path: routes.legalFramework,
        //   banner:
        //     "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464052/FIXOUROIL/Nav_-_About_Legal_Framework_gdyrwv.png",
        // },
        {
          title: "OIL & GAS AT A GLANCE",
          path: routes.oilAndGas,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464028/FIXOUROIL/Nav_-_About_aeru4a.png",
        },
      ],
    },
  },
  {
    title: "EXTRACTIVE UPDATES",
    dropdown: true,
    dropdownData: {
      banner:
        "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464550/FIXOUROIL/Nav_-_Updates_k0gdjn.png",
      nav: [
        {
          title: "O.G.P",
          path: routes.ogp,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464025/FIXOUROIL/Nav_-_Updates_O.G.P_hq4roj.png",
        },
        {
          title: "PIA",
          path: routes.pia,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464025/FIXOUROIL/Nav_-_Updates_O.G.P_hq4roj.png",
        },
        {
          title: "Extractive projects",
          path: routes.extractiveProjects,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619464052/FIXOUROIL/Nav_-_About_About-us_miesaw.png",
        },
        {
          title: "Corruption cases",
          path: routes.corruptionCases,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429175/FIXOUROIL/Nav_-_Resources_Infographics_lo50wq.png",
        },
        {
          title: "African mining vision",
          path: routes.africanMiningVision,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429176/FIXOUROIL/Nav_-_Resources_rt5fta.png",
        },
      ],
    },
  },
  {
    title: "DATA",
    path: routes.data,
  },
  {
    title: "RESOURCES",
    dropdown: true,
    dropdownData: {
      banner:
        "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429176/FIXOUROIL/Nav_-_Resources_rt5fta.png",
      nav: [
        {
          title: "INFOGRAPHICS",
          path: routes.infographics,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429175/FIXOUROIL/Nav_-_Resources_Infographics_lo50wq.png",
        },
        // {
        //   title: "OTHER PUBLICATIONS",
        //   path: routes.otherPublications,
        //   banner:
        //     "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429177/FIXOUROIL/Nav_-_Resources_Other_Publications_sfsxsp.png",
        // },
        {
          title: "POLICY BRIEF",
          path: routes.policybrief,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429180/FIXOUROIL/Nav_-_Resources_Policy_Briefs_x3wppt.png",
        },
        {
          title: "VIDEOS",
          path: routes.videos,
          banner:
            "https://res.cloudinary.com/budgit/image/upload/q_10/v1619429178/FIXOUROIL/Nav_-_Resources_Videos_kqzbl8.png",
        },
      ],
    },
  },
  {
    title: "BLOG",
    path: routes.blog,
  },
];

export default navList;
