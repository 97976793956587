import { gql } from "@apollo/client";

export const GET_COMMITMENTS = gql`
  query getCommitments($limit: Int!, $offset: Int!){
    FixOurOilCommitments(order_by: { createdAt: desc }, limit: $limit, offset: $offset, where: {deleted: {_eq: false}}) {
      name
      id
      description
    }
    FixOurOilCommitments_aggregate(where: {deleted: {_eq: false}}) {
    aggregate {
      count
    }
  }
  }
`;

export const COMMITMENTS_ACTIVITIES = gql`
  query COMMITMENTS_ACTIVITIES($id: Int!) {
    FixOurOilCommitments(
      limit: 1, offset: 0,
      order_by: { createdAt: desc }
      where: { deleted: { _eq: false }, id: { _eq: $id } }
    ) {
      id
      name
      description
      FixOurOilCommitmentItems(order_by: { createdAt: desc }) {
        name
        id
        startDate
        endDate
        completeRate
        icon
      }
    }
  }
`;

export const GET_COMMITMENT_ACTIVITIES = gql`
query getCommitmentActivities($id: Int!) {
  FixOurOilCommitmentItems(where: {FixOurOilCommitment: {id: {_eq: $id}}}, order_by: {createdAt: desc}) {
    name
    id
    startDate
    icon
    endDate
    completeRate
    commitmentId
  }
}

`

export const GET_COMMITMENT_AND_ACTIVITIES = gql`
query getCommitmentActivity($limit: Int!, $offset: Int!) {
  FixOurOilCommitments(limit: $limit, offset: $offset, order_by: {createdAt: desc}, where: {deleted: {_eq: false}}) {
    id
    name
    description
    FixOurOilCommitmentItems(order_by: {createdAt: desc}) {
      name
      id
      startDate
      endDate
      completeRate
      icon

    }
  }
    FixOurOilCommitments_aggregate(where: {deleted: {_eq: false}}) {
    aggregate {
      count(columns: id)
    }
  }
}



`;