/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useLottie } from "lottie-react";

const Loader = ({ loader, loop = true }) => {
  const options = {
    animationData: loader,
    loop,
    autoplay: true,
  };

  const { View } = useCallback(useLottie(options), []);

  return <>{View} </>;
};

export default Loader;
