import React, { useState, useCallback, useEffect, useRef } from "react";

const useModal = ({ backgroundClose = false, props } = {}) => {
  const [displayedChild, setDisplayedChild] = useState();
  const displayedChildWrapper = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback((placeholder) => {
    setDisplayedChild(placeholder);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
      setDisplayedChild(null);
    };
  }, []);

  const closeModal = useCallback(() => {
    setDisplayedChild(null);
    setIsModalOpen(false);
  }, []);

  const backgroundCloseHandler = (e) => {
    e.stopPropagation();

    if (
      displayedChildWrapper.current.contains(e.target) &&
      displayedChildWrapper.current.id !== e.target.id
    ) {
      return;
    }
    setDisplayedChild(null);
    setIsModalOpen(false);
  };

  const placeholder = isModalOpen ? (
    <div
      onClick={backgroundClose ? backgroundCloseHandler : () => {}}
      style={{ zIndex: 1000 }}
      className="fixed top-0 bottom-0 bg-black bg-opacity-30 right-0 left-0 flex  "
    >
      <div className="bg-transparent h-screen w-full overflow-scroll flex items-center">
        <div
          id="childWrapper"
          data-some="childWrapper"
          ref={displayedChildWrapper}
          className=" m-auto w-full flex justify-center "
        >
          {displayedChild}
        </div>
      </div>
    </div>
  ) : null;
  return {
    modal: placeholder,
    openModal,
    setDisplayedChild,
    closeModal,
  };
};

export default useModal;
